.collapsed-content {
  @extend %collapsedContent;

  &--primary {
    max-height: 56px;
  }
}

.-pt- {
  &-nope- {
    padding-top: 0;
  }
  &-x- {
    padding-top: var(--offset-x);
  }
  &-s- {
    padding-top: var(--offset-s);
  }
  &-m- {
    padding-top: var(--offset-m);
  }
  &-l- {
    padding-top: var(--offset-l);
  }
}

.-metadata- {
  padding: 2px 0;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  svg {
    margin-right: var(--offset-xx);
    fill: currentColor;
    position: relative;
    top: 2px;
  }

  a {
    color: currentColor;
  }

  @media screen and (min-width: 601px) {
    svg {
      margin-right: var(--offset-x);
    }
  }
}

.-text-center- {
  text-align: center;
}


/* #start Widget */
.-tb-event {
  position: fixed;
  top: 0;
  right: 100%;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 2000;
  overflow: hidden;

  &[data-show="is-open"] {
    right: 0;
  }
}

button[data-hash] {
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
  }
}
/* #end Widget */

.-box-shadow- {
  border-radius: var(--radius-l);
  box-shadow: 0 1px 4px var(--color-shadow);
  background-color: #fff;
//  #D1D6E2
}

.-box-shadow-border- {
  border-radius: var(--radius-l);
  box-shadow: 0 1px 4px var(--color-shadow);
  background-color: #fff;
  border: 1px solid var(--color-shadow);
//  #D1D6E2
}


.-hint- {
  font-size: 75%;
  height: 20px;
  display: inline-flex;
  align-items: center;
  padding: 0 var(--offset-x);
  border-radius: var(--radius-l);

  &-grey- {
    color: var(--color-text-light);
  }

  &-active- {
    background-color: rgba(var(--color-green-rgb), .3);
    color: var(--color-title);
  }

  &-archive- {
    background-color: rgba(var(--color-shadow-rbg), .3);
    color: var(--color-title);
  }

  @media screen and (min-width: 601px) {
    font-size: 87.5%;
    height: 30px;
    padding: 0 var(--offset-s);
  }

}

.-box-video- {
  padding-bottom: 62.5%;
  width: 100%;
  position: relative;

  &-list- {
    display: flex;
    flex-wrap: wrap;
  }
  &-tile- {
    flex-shrink: 0;
    width: 100%;

    & + .-box-video--tile- {
      margin-top: var(--offset-x);
    }

    @media screen and (min-width: 601px) {
      margin-bottom: var(--offset-x);
      width: calc(50% - var(--offset-xx));

      &:nth-child(2n) {
        margin-right: var(--offset-x);
      }

      & + .-box-video--tile- {
        margin-top: 0;
      }
    }
  }


  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
  }
}

.-box-photo- {

  &-list- {
    //margin: 0 -9px;
    //display: flex;
    //flex-wrap: wrap;
    //padding-bottom: 1px;
    //align-items: flex-start;

    margin-bottom: var(--offset-xx);

    @media screen and (min-width: 601px) {
      column-count: 2;
      column-gap: var(--offset-x);

      picture {
        margin-bottom: var(--offset-x);
      }
    }

    @media screen and (min-width: 1281px) {
      column-count: 3;
    }

  }

  img {
    transition: all .2s;
    filter: grayscale(1);
    cursor: pointer;
    border-radius: 10px;

    @media screen {
      &:hover {
        filter: grayscale(0);
      }
    }
  }

  picture {
    display: inline-block;
  }

  & > img {

    object-fit: cover;
    object-position: center;
  }

  &-popup- {
    position: fixed;
    top: 150%;
    left: 0;
    right: 0;
    height: 100vh;
    opacity: 0;
    z-index: 100000;
    background-color: var(--color-title);
    transform: rotateX(90deg);
    transform-origin: bottom;
    transition: all .3s;
    
    &[data-show="view"] {
      opacity: 1;
      top: 0;
      transform: none;
    }

    .btn--close {
      position: absolute;
      top: 0;
      right: 0;
      color: #fff;
    }

    .btn--circle-nav {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      &.-next- {
        right: 18px;
      }
      &.-prev- {
        left: 18px;
      }
    }

    .-slider- {
      height: 100%;

      .-slider--thumbs- {
        height: 100%;

        & > div {
          height: 100%;

          & > div {
            height: 100%;
          }
        }
      }

      .-bpp-slide- {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.pagination {
  padding-top: var(--offset-s);
  background-color: var(--color-bg);

  ul {
    display: flex;
    justify-content: center;
  }
  li {
    padding: 0 12px;
    display: none;

    @media screen and (max-width: 600px) {
      padding: 0 1%;
    }

    & > span {
      display: flex;
      height: 100%;
      align-items: center;
    }

    &.-load-more- {
      display: initial;
    }

    display: initial;
  }

  .btn--circle {
    //box-shadow: 0 4px 4px var(--color-shadow);
    background-color: #fff;
    border: 1px solid var(--color-shadow);
    color: var(--color-title);
    font-size: 131.25%;

    svg {
      width: 8px;
      height: 14px;
    }

    &.-prev-,
    &.-next- {
      border: 1px solid transparent;
      box-shadow: 0 4px 4px var(--color-shadow);
    }

    @media screen {
      &:hover {
        border-color: var(--color-shadow);
        box-shadow: 0 4px 4px var(--color-shadow);
        font-weight: 700;
      }
    }

    &.-current- {
      font-weight: 900;
      border-color: var(--color-title);
    }
  }

}

/*  Sizes

16px = 100%

18px = 112.5%

21px = 131.25%

24px = 150%

30px = 187.5%

60px = 375%

*/
.-fz-12- {
  font-size: 12px;
  //font-size: 112.5%;
}
.-fz-14- {
  font-size: 14px;
  //font-size: 112.5%;
}
.-fz-16- {
  //font-size: 16px;
  font-size: 100%;
}
.-fz-18- {
  //font-size: 18px;
  font-size: 112.5%;
}

.-fz-20- {
  font-size: 20px;
}

.-fz-21- {
  //font-size: 24px;
  font-size: 131.25%;
}
.-fz-24- {
  //font-size: 24px;
  font-size: 150%;
}
.-fz-30- {
  //font-size: 36px;
  font-size: 187.5%;
}
.-fz-36- {
  font-size: 36px;
}
.-fz-38- {
  font-size: 38px;
}
.-fz-40- {
  font-size: 40px;
}
.-fz-60- {
  //font-size: 60px;
  font-size: 375%;
}
.-fz-72- {
  font-size: 72px;
}
.-fz-144- {
  font-size: 144px;
}

.-tile-list- {
  display: flex;
  flex-wrap: wrap;
  margin-left: -9px;
  margin-right: -9px;
  width: calc(100% + 18px);

  &.-tile-list-singular {
    justify-content: center;
  }

  .-tl-3-,
  .-tl-2-,
  .-tl- {
    margin: 9px;
    flex-shrink: 0;
    width: calc(100% - 18px);
  }

  @media screen and (min-width: 601px) {
    .-tl-3-,
    .-tl-2-,
    .-tl- {
      width: calc(50% - 18px);
    }
  }

  @media screen and (min-width: 961px) {
    .-tl- {
      width: calc((100% / 3) - 18px);
    }
  }

  @media screen and (min-width: 1281px) {
    .-tl- {
      width: calc(25% - 18px);
    }
    .-tl-3- {
      width: calc((100% / 3) - 18px);
    }
  }
  @media screen and (min-width: 1441px) {
    .-tl-2- {
      width: calc((100% / 3) - 18px);
    }
    .-tl- {
      width: calc(20% - 18px);
    }
    .-tl-3- {
      width: calc(25% - 18px);
    }
  }

  &.-scrolled- {
    @media screen and (max-width: 600px) {
      overflow-x: auto;
      overscroll-behavior-x: none;
      scroll-behavior: smooth;
      flex-wrap: nowrap;

      .-tl- {
        max-width: 60%;
      }
    }
  }

  .-tl-2-.singular-video {
    width: 100%;

    @media screen and (min-width: 901px) {
      width: 50%;
    }

    @media screen and (min-width: 1281px) {
      width: 42%;
    }

    @media screen and (min-width: 1441px) {
      width: 33%;
    }
  }
}

.section--images .-tl- {
  img {
    border-radius: var(--radius-l);
  }
}

.-blog- {
  padding-bottom: 48px;

  .h1 {
    margin-top: var(--offset-xs);
  }

  @media screen and (min-width: 601px) {
    .h1 {
      margin-top: var(--offset-s);
    }
  }
  @media screen and (min-width: 961px) {
    .h1 {
      margin-top: 0;
    }
  }
  @media screen and (min-width: 1052px) {
    padding-bottom: 0;
  }
}


// Forms
.form {

  &--input {
    height: 52px;
    padding: 0 var(--offset-x);
    font-weight: 700;
    color: var(--color-title);
    border-radius: var(--radius-l);
    border: 1px solid var(--color-shadow);
    font-size: 100%;
    width: 100%;
    transition: all .2s;

    &.readonly-input {
    background-color: #f0f0f0;
    color: #999;
    cursor: not-allowed;
    border: 1px solid #ccc;
    }
    
    &[disabled] {
      opacity: .5;
      pointer-events: none;
      background-color: #eee;
    }

    &[type=password] {
      font-size: 150%;
    }

    @media screen {
      &:hover {
        box-shadow: 0 4px 4px var(--color-shadow);
      }
    }

    &:focus {
      border-color: var(--color-title);
    }

	&::placeholder {
		opacity: 0.5;
		@-moz-document url-prefix() {
			opacity: 0.25;
		}
	}

    &-change {
      position: relative;

      button {
        width: 50px;
        height: 50px;
        border: 0;
        border-radius: var(--radius-l);
        position: absolute;
        right: 1px;
        top: 1px;
        z-index: 1;
        align-items: center;
        justify-content: center;
        display: none;
        color: var(--color-title);

        svg {
          fill: currentColor;
        }

        @media screen {
          &:hover {
            color: var(--color-primary);
          }
        }
      }

      input[disabled] + button {
        display: flex;
      }
    }
  }

  &--textarea {
    width: 100%;
    resize: none;
    overflow-y: auto;
    padding: var(--offset-x);
    font-weight: 700;
    color: var(--color-title);
    border-radius: var(--radius-l);
    border: 1px solid var(--color-shadow);

    &:focus {
      border-color: var(--color-title);
    }

    @media screen {
      &:hover {
        box-shadow: 0 4px 4px var(--color-shadow);
      }
    }
  }

  &--radio {

    & + .form--radio {
      margin-top: var(--offset-xx);
    }

    label {
      display: inline-flex;
      align-items: center;
      color: var(--color-title);
    }

    svg {
      stroke: currentColor;
      flex-shrink: 0;
      margin-right: var(--offset-x);
    }

    input {
      display: none;

      &[checked] + label {
        font-weight: 600;

        svg {
          --color-check: var(--color-title);
        }
        //.-check- {
        //  fill: currentColor;
        //}
      }
    }

    &.-is-edit- {
      display: flex;
      align-items: center;

      label {
        margin-right: var(--offset-xs);
      }
    }

    &-edit {
      height: 36px;
      flex-shrink: 0;
      margin-right: 0;
      margin-left: auto;
      display: inline-flex;
      align-items: center;

      svg {
        margin-right: 6px;
        stroke-width: 0;
      }

      @media screen {
        &:hover {
          color: var(--color-primary);
        }
      }
    }
  }

  &--footer {
    //padding-top: var(--offset-x);
    text-align: center;

    button {
      & + button {
        margin-left: var(--offset-xx);

        @media screen and (min-width: 801px) {
          margin-left: var(--offset-x);
        }
      }
    }
  }

  &--group {
    margin-bottom: var(--offset-s);

    label {
      line-height: 1;
      padding: calc(var(--offset-s) / 9) var(--offset-x) calc(var(--offset-m) / 9);
      color: var(--color-text-light);
      display: block;
    }

    &-photo {
      margin-bottom: var(--offset-x);
      display: flex;
      align-items: center;

      img {
        margin-right: var(--offset-x);
        max-width: 52px;
        box-shadow: 0 2px 4px var(--color-shadow);
        border-radius: 52px;
      }

      input {
        display: none;
      }
    }

    &-error {
      display: none;
      font-size: 87.5%;
      color: var(--color-primary);
      padding: var(--offset-xx) var(--offset-x);
    }

    &--hint {
      font-size: 75%;
      padding-top: 12px;
    }

    &.-is-error- {
      .form--group-error {
        display: block;
      }

      .form--textarea,
      .form--input {
        border-color: var(--color-primary);
        box-shadow: 0 4px 4px rgba(var(--color-primary-rgb), .5);
      }
    }

  }

  &--hint {
    display: flex;
    color: var(--color-text-light);
    align-items: center;
    padding: var(--offset-xx) var(--offset-x) 0;
    font-size: 87.5%;
    line-height: 1.35;

    svg {
      fill: currentColor;
      margin-right: var(--offset-xx);
    }

    & > div {
      flex-grow: 1;
      max-width: calc(100% - 30px);
    }
  }

  &--label {
    font-weight: bold;
    font-size: 112.5%;
    margin-bottom: var(--offset-sx);
  }

  &--loading {
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    justify-content: center;
    top: 0;
    left: 0;
    align-items: flex-end;
    padding-bottom: 9px;
    background-color: rgba(#fff,.6);
    border-radius: var(--radius-l);
    z-index: 50;
    color: var(--color-title);

    &.-center- {
      align-items: center;
    }

    svg {
      fill: currentColor;
      animation: spin 1s linear infinite;
    }
  }

  &--line {
    margin-bottom: var(--offset-s);

    .form--hint {
      margin-bottom: var(--offset-x);
      padding-left: 0;
    }
    .-notice- {
      margin-bottom: var(--offset-x);
    }

    .dropdown--trigger-item {
      @media screen {
        &:hover {
          box-shadow: 0 4px 4px var(--color-shadow);
        }
      }
    }
  }

  &--error {
    color: var(--color-primary);
    padding: var(--offset-xx) var(--offset-x);
  }
  
  &--saved {
    color: var(--color-green);
    text-align: center;
    margin-bottom: var(--offset-s);
  }
}

// Paddings
.-p- {
  &-n- {
    padding: 0;
  }
  &-medium- {
    padding: var(--offset-s) var(--offset-m);
  }
  &-normal- {
    padding: var(--offset-s) 56px;
  }
}

.-trigger- {
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 5;
  }
}

span[data-scroll-panel] {
  position: relative;
  top: -22px;
  height: 0;
  overflow: hidden;
  opacity: 0;

  &[hidden] {
    display: block;
  }
}

.-loading- {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  svg {
    fill: currentColor;
    animation: spin 1s linear infinite;
  }

}
