.single--post {
  padding: var(--offset-xs) 0;

  &-cover {
    figcaption {
      padding: var(--offset-x) 0 var(--offset-xs);
      font-weight: 700;
      color: var(--color-text-light);
    }
  }

  &.single-news {
    @media screen and (min-width: 1421px) {
      display: flex;
      justify-content: space-between;
      padding: var(--offset-sm) 0;
      width: 100%;

        .news-content {
          width: 800px;
        }
    }

    .news-content blockquote.toitalic {
      font-style: italic;
      font-weight: 400;
      border-left: 4px solid #eaeaef;
      margin: 40px 0 32px;
      padding: 8px 20px;
    } 

    .column-advertising {
      // width: calc((100% - 800px) / 2);
      padding: 5px 10px;
      overflow: hidden;

      @media screen and (max-width: 1420px) {
        display: none;
      }

      & img {
        @media screen and (max-width: 1420px) {
          display: none;
        }
      }

      .advertising {
        margin-bottom: 20px; 
      }
    }
  }

  @media screen and (min-width: 961px) {
    padding: var(--offset-sm) calc((100% - 800px) / 2);
    box-shadow: 0 2px 9px #e1e1e1;
    border-radius: 10px;
  }

  .images-source {
    font-style: italic;
    font-weight: 400;
    padding-top: 5px;
  }

  .is-youtube {
    padding-bottom: 62.5%;
    width: 100%;
    height: 0;
    position: relative;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: var(--radius-l);
    }
  }

  .style-content {

    a {
      color: var(--color-primary);
      border-bottom: 1px solid;

      @media screen {
        &:hover {
          color: var(--color-title);
        }
      }
    }

  }

  &-footer {
    padding: var(--offset-xs) 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    form {
      margin-bottom: var(--offset-x);
    }

    @media screen and (min-width: 801px) {
      justify-content: space-between;
      flex-direction: row;

      form {
        margin-bottom: 0;
      }
    }
  }

}

// Single news style
.news-autor-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
  border-top: 4px solid #eaeaef;
  border-bottom: 4px solid #eaeaef;
  padding: 10px 0;
  font-style: italic;
  color: var(--color-text-light);
  font-size: 16px;
  line-height: 1.25;
}

.news-autor-info {
  display: flex;
  gap: 10px;
  align-items: center;
}

.news-autor-image {
  img {
    display: block;
    border-radius: 50%;
    aspect-ratio: 1 / 1;
  }
}

.news-autor-desc {
  display: flex;
  flex-direction: column;
  gap: 0;
  align-items: flex-start;

  @media screen and (min-width: 701px) {
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }
}

.news-autor-name,
.news-autor-title {
  // padding-right: 10px;
  // border-right: 3px solid #eaeaef;

  @media screen and (min-width: 701px) {
    padding-right: 10px;
    border-right: 3px solid #eaeaef;
  }
}

.news-autor-name {
  font-weight: 700;
}

.news-autor-title {

}

.news-autor-social {
  display: flex;
  gap: 6px;

  .bio-social {
    margin-right: 0;
    color: var(--color-text-light);

    &:hover {
      color: var(--color-primary);
    }
  }
}

.news-autor-date {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 451px) {
    flex-direction: row;
    gap: 5px;
  }
}