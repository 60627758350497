.card--abowe {
  border-radius: var(--radius-l);
  display: flex;
  box-shadow: 0 4px 8px rgba(#000, .15);

  @media screen and (min-width: 601px) {
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(#000, 0);
  }

  .card--thumb {
    width: 109px;
    flex-shrink: 0;

    @media screen and (min-width: 601px) {
      width: initial;
    }
  }

  img {
    display: block;
    border-radius: var(--radius-l) 0 0 var(--radius-l);
    height: 160px;
    object-fit: cover;
    object-position: center;

    @media screen and (min-width: 601px) {
      max-width: 100%;
      height: 240px;
      width: 100%;
      border-radius: var(--radius-m) var(--radius-m) 0 0;
    }
  }

  .card--content {
    padding: var(--offset-x);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .card--title {
    font-weight: 700;
    padding-top: 8px;
    color: var(--color-text);
    max-height: 50px;
    height: 50px;
    overflow: hidden;
    display: block;
    line-height: 1.35;
  }

  .card--datetime {
    color: var(--color-text-light);
  }

}
