:root {
  --picker-width: 40px;

  @media screen and (min-width: 481px) {
    --picker-width: 60px;
  }
}

.-tb-picker- {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  user-select: none;

  button {
    &[disabled] {
      opacity: .3;
      cursor: no-drop;
    }
  }

  &-wrap- {
    width: calc(var(--picker-width) * 7);
    flex-shrink: 0;
  }

  &-head- {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: var(--offset-xs);

    .btn--pn {
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        transform-origin: center;
      }

      &.-select--next-view- {
        svg {
          transform: rotate(-90deg);
        }
      }

      &.-select--prev-view- {
        svg {
          transform: rotate(90deg);
        }
      }

      @media screen and (min-width: 481px) {
        width: 48px;
      }
    }

    .btn--title {
      font-size: 112.5%;
      text-transform: uppercase;
      font-weight: 900;
      width: 200px;
      flex-shrink: 0;

      @media screen and (min-width: 481px) {
        font-size: 131.25%;
        width: 220px;
      }
    }

    button {
      height: 40px;

      @media screen and (min-width: 481px) {
        height: 48px;
      }
    }
  }

  &-body- {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;

    &-week- {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: var(--offset-x);
      font-weight: 600;

      span {
        flex-shrink: 0;
        width: var(--picker-width);
        text-transform: lowercase;
        color: var(--color-text-light);
        text-align: center;
      }
    }

    button {
      width: var(--picker-width);
      height: 30px;
      border-radius: var(--radius-s);
      font-size: 87.5%;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      margin-bottom: 4px;
      position: relative;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 1;
      }

      &.-selected- {
        background-color: var(--color-title);
        color: #fff;
        font-weight: 600;
      }

      &.-marked- {
        background-color: rgba(var(--color-title-rgb), .1);
        color: var(--color-title);
      }

      &[data-mark] {
        &:after {
          content: '';
          position: absolute;
          border-radius: 50%;
          top: 10px;
          margin-left: 14px;
          background-color: var(--color-primary);
        }
      }

      &[data-mark='1'] {
        &:after {
          width: 4px;
          height: 4px;
          margin-top: -2px;
        }
      }
      &[data-mark='2'] {
        &:after {
          width: 6px;
          height: 6px;
          margin-top: -3px;
        }
      }
      &[data-mark='many'] {
        &:after {
          width: 8px;
          height: 8px;
          margin-top: -4px;
        }
      }

      @media screen and (min-width: 481px) {
        font-size: 100%;
        height: 40px;
      }

      &:hover {
        background-color: rgba(var(--color-title-rgb), .1);

        &.-selected- {
          background-color: var(--color-title);
        }

        &.-marked- {
          background-color: rgba(var(--color-title-rgb), .1);
          color: var(--color-title);
        }
      }

    }

    &.-completed- {
      .-from- {
        border-radius: 4px 0 0 4px;
        background-color: rgba(var(--color-title-rgb), 1);
        color: #fff;

        & ~ button {
          background-color: rgba(var(--color-title-rgb), 1);
          border-radius: 0;
          color: #fff;
        }

        & ~ .-to- {
          border-radius: 0 4px 4px 0;

          & ~ button {
            background-color: rgba(var(--color-title-rgb), 0);
            color: var(--color-title);
            border-radius: 4px;

            &:hover {
              background-color: rgba(var(--color-title-rgb), .1);
            }
          }
        }
      }
    }

    &.-selected- {

      &-nope- {
        button {
          &:hover {
            background-color: rgba(var(--color-title-rgb), .1);

            &.-selected- {
              background-color: var(--color-title);
            }
          }
        }
      }

      &.-selected--right- {

        button:hover {
          border-radius: 4px 0 0 4px;
          background-color: rgba(var(--color-title-rgb), .1);

          & ~ button {
            background-color: rgba(var(--color-title-rgb), .1);
            border-radius: 0;
          }

          & ~ .-selected- {
            border-radius: 4px 0 0 4px;
            & ~ button {
              background-color: rgba(var(--color-title-rgb), 0);

            }
          }

          &.-selected- {
            border-radius: 4px;
            & ~ button {
              background-color: rgba(var(--color-title-rgb), 0);
            }
          }

        }

      }

      &.-selected--left- {

        button:hover {
          border-radius: 4px 0 0 4px;
          background-color: rgba(var(--color-title-rgb), .1);

          //.-selected--left- button.-selected-

          & ~ button {
            background-color: rgba(var(--color-title-rgb), .1);
            border-radius: 0;
          }

          & ~ .-selected- {
            border-radius: 4px 0 0 4px;
            & ~ button {
              background-color: rgba(var(--color-title-rgb), 0);

            }
          }

          &.-selected- {
            border-radius: 4px;
            & ~ button {
              background-color: rgba(var(--color-title-rgb), 0);
            }
          }

        }

      }


      button:hover {

      }


    }

  }

  &-foot- {
    padding-top: var(--offset-xs);
    display: flex;
    flex-direction: column;

    button {
      width: 100%;
      min-width: 100%;
      height: 42px;
    }

    .btn--primary {
      margin-bottom: var(--offset-xx);

      span {
        margin-left: var(--offset-xx);

        &:empty {
          max-width: 0;
          overflow: hidden;
          margin-left: 0;
        }
      }
    }

    @media screen and (min-width: 481px) {
      flex-direction: row;

      button {
        height: 52px;
      }

      .btn--primary {
        margin-left: var(--offset-xx);
        flex-grow: 1;
        margin-bottom: 0;
        max-width: calc(100% -  var(--offset-xx) - 140px);
        min-width: initial;

        &-border {
          min-width: 140px;
          width: 140px;
          order: -1;
        }
      }
    }
  }

  &-aside- {
    display: flex;
    flex-shrink: 0;
    width: 220px;
    flex-direction: column;
    margin: 0 0 0 var(--offset-m);
    padding-left: var(--offset-s);
    border-left: 1px solid var(--color-border);
    align-self: stretch;
    justify-content: center;
    color: var(--color-text-light);

    &:empty {
      display: none;
    }

    a {
      font-size: 100%;
      font-weight: 600;
      color: currentColor;
      margin: var(--offset-x) 0;

      @media screen {
        &:hover {
          color: var(--color-primary);
        }
      }
    }
    
  }

}

.filter-primary .-tb-picker-aside {
  @media screen and (max-width: 1051px) {
    display: none;
  }
}

.-times- {

  &-title- {
    font-weight: 600;
    margin: 18px 0 12px 0;

    @media screen and (min-width: 1380px) {
      margin: 48px 0 24px 0;
    }
  }

  &-arr- {
    display: flex;
    flex-wrap: wrap;

    .-btn--time- {
      min-width: 80px;
      border-radius: 10px;
      border: 1px solid var(--color-shadow);
      height: 32px;
      margin: 0 6px 6px 0;

      &[data-checked="true"] {
        background-color: var(--color-title);
        border-color: var(--color-title);
        color: #fff;
      }

      @media screen {
        &:hover {
          background-color: rgba(var(--color-title-rgb), .1);

          &[data-checked="true"] {
            background-color: var(--color-title);
          }
        }
      }
    }

    @media screen and (min-width: 1380px) {
      .-btn--time- {
        margin: 0 12px 12px 0;
        height: 36px;
      }
    }
  }

  &.-tb-picker--aside- {
    width: 100%;
    align-items: center;
    margin-left: 0;
    padding-left: 0;
    border-left: none;
    border-top: 1px solid var(--color-border);

    @media screen and (min-width: 1380px) {
      width: 260px;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 0 0 0 var(--offset-m);
      padding-left: var(--offset-s);
      border-left: 1px solid var(--color-border);
      border-top: none;
    }
    //@media screen and (min-width: 1380px) {
    //  width: 260px;
    //  justify-content: flex-start;
    //}
  }

}
