.card--big {
  border-radius: var(--radius-l);
  position: relative;
  overflow: hidden;
  color: #fff;
  background-color: rgba(var(--color-title-rgb), .5);
  font-size: 150%;

  @media screen and (min-width: 481px) {
    font-size: 187.5%;
  }
  @media screen and (min-width: 601px) {
    font-size: 250%;
  }
  @media screen and (min-width: 801px) {
    font-size: 300%;
  }
  @media screen and (min-width: 1052px) {
    font-size: 375%;
  }
  @media screen and (min-width: 1280px) {
    font-size: 450%;
  }

  img {
    border-radius: var(--radius-l);
    display: block;
    /*min-height: 280px;*/
    object-fit: cover;
    object-position: center;
  }

  .card {

    &--content {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      padding: 0 var(--offset-s) var(--offset-x);

      @media screen and (min-width: 1441px) {
        padding: 0 116px 72px;
      }

      @media screen and (min-width: 961px) and (max-width: 1440px) {
        padding: 0 96px 54px;
      }

      @media screen and (min-width: 601px) and (max-width: 960px) {
        padding: 0 var(--offset-sm) var(--offset-s);
      }

    }

    &--title {
      margin-bottom: .25em;
      text-transform: uppercase;
      font-weight: 900;
      color: currentColor;

      strong {
        font-weight: 900;
      }

    }

  }

  .-metadata- {
    font-size: 60%;
    font-weight: 700;
    margin-bottom: .5em;

    a {
      color: currentColor;
    }
  }

  .btn--secondary {
    color: var(--color-title);
    font-size: 36px;
    flex-shrink: 0;
    max-width: 200px;
    display: none;

    @media screen and (min-width: 1052px) {
      display: flex;
    }
  }

}
