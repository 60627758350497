.card--alphabet {
  display: flex;
  align-items: baseline;
  line-height: 1.35;

  .card--head {
    flex-shrink: 0;
    width: 40px;
    font-size: 131.25%;
    position: sticky;
    top: 46px;
    padding: var(--offset-xx) 0;
    font-weight: 900;

    @media screen and (min-width: 601px) {
      font-size: 175%;
      width: 75px;
    }
    @media screen and (min-width: 801px) {
      font-size: 175%;
      width: 125px;
    }
    @media screen and (min-width: 961px) {
      font-size: 250%;
      top: 36px;
    }
    @media screen and (min-width: 1281px) {
      font-size: 350%;
    }
  }

  .card--content {
    padding: var(--offset-xx) 0;
    max-width: calc(100% - 40px);
    flex-grow: 1;
    margin-right: 0;
    margin-left: auto;

    @media screen and (min-width: 601px) {
      max-width: calc(100% - 75px);
    }
    @media screen and (min-width: 801px) {
      max-width: calc(100% - 125px);
    }
  }

  .card--column-list {
    li {
      margin-bottom: var(--offset-xx);

      &:last-child {
        margin-bottom: 0;
      }

      &[data-show='0'] {
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        max-width: 0;
        width: 0;
        margin: 0;
        padding: 0;
      }
    }

    a {
      color: var(--color-text-light);

      @media screen {
        &:hover {
          color: var(--color-primary);
        }
      }
    }
  }
  
  @media screen and (min-width: 801px) {
    box-shadow: 0 2px 4px var(--color-shadow);
    border: 1px solid var(--color-shadow);
    border-radius: var(--radius-l);
    padding: var(--offset-x) var(--offset-s);
    margin-bottom: var(--offset-x);

    .card--column-list {
      column-count: 2;
      column-gap: var(--offset-s);
    }
  }

  @media screen and (min-width: 1281px) {
    margin-bottom: var(--offset-s);

    .card--column-list {
      column-count: 3;
      column-gap: var(--offset-s);
    }
  }

  &[data-show='0'] {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    max-width: 0;
    width: 0;
    margin: 0;
    padding: 0;
    border: 0;
  }

}
