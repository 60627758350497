button[hidden] {
  display: none;
}

.btn {
  cursor: pointer;
  border-radius: 10px;

  &--outline {
    border: 1px solid currentColor;
  }


}

.-color-title- {
  color: var(--color-title);
}
.-color-text- {
  color: var(--color-text);
}
.-color-text-light- {
  color: var(--color-text-light);
}

.btn--rect {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 50%;
    height: 50%;
    fill: currentColor;
    transition: all .2s;

    &.-is-mini- {
      width: 12px;
      height: 12px;
    }
  }

  @media screen and (min-width: 801px) {
    width: 55px;
    height: 55px;
  }
}

.btn--square {
  border-radius: var(--radius-l);
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: currentColor;
  }

  @media screen and (min-width: 601px) {
    width: 52px;
    height: 52px;
  }
}

.btn--link {
  height: 1.33em;
  font-size: 131.25%;
  font-weight: 900;
  text-transform: uppercase;
  padding: 0;

  &-black {
    color: var(--color-title);

    @media screen {
      &:hover {
        color: var(--color-primary);
      }
    }
  }

  &-red {
    color: var(--color-primary);

    @media screen {
      &:hover {
        color: var(--color-title);
      }
    }
  }

  &-easy {
    font-weight: 600;
    border: 1px solid #011031;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 16px;
    line-height: 24px;
    color: #011031;
    width: 100%;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen {
      &:hover {
        color: var(--color-primary);
      }
    }
  }

  &-light {
    font-size: 100%;
    font-weight: 600;
    color: #eee;

    @media screen {
      &:hover {
        color: var(--color-primary);
      }
    }
  }

}

.btn--link-easy svg {
  margin-right: 16px;
}

.btn--load-ticket {
  color: var(--color-title);
  border: 1px solid currentColor;
  border-radius: var(--radius-l);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  font-weight: 600;
  background-color: transparent;

  svg {
    fill: currentColor;
    margin-right: 9px;
  }

  @media screen {
    &:hover {
      color: var(--color-primary);
    }
  }
}

.btn--wallet {
  height: 52px;
  background-color: var(--color-title);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 87.5%;
  border-radius: var(--radius-l);

  & > span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      font-size: 120%;
    }
  }

  svg {
    margin-right: var(--offset-x);
  }

  @media screen {
    &:hover {
      //box-shadow: 0 1px 4px #040707;
      //background-color: var(--color-title);
      background-color: #040707;
    }
  }
}

.link--btn {
  padding: 0;
  font-weight: 700;
  height: 1.4em;
  font-size: 100%;

  @media screen {
    &:hover {
      color: var(--color-primary);
    }
  }

  @media screen and (min-width: 601px) {
    font-size: 112.5%;
  }

  &.support {
    color: var(--color-primary);
    font-size: 21px;

    @media screen and (min-width: 651px) {
      border-bottom: 1px solid var(--color-primary);
    }

    @media screen and (max-width: 650px) {
      color: var(--color-primary);
      width: 100%;
      height: 60px;
      font-size: 20px;
      border: 2px solid var(--color-primary);
      border-radius: 8px;
      display: inline-block;
      text-align: center;
      line-height: 60px;
    }
  }
}

.btn--primary {
  transition: all .2s;
  height: 52px;
  display: inline-flex;
  min-width: 230px;
  font-size: 100%;
  background-color: var(--color-primary);
  font-weight: 900;
  color: #fff;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  box-shadow: 0 2px 6px rgba(var(--color-primary-rgb), .5);
  
  @media screen {
    &:hover {
      background-color: #E42D42;
    }
  }
}

.btn--primary-border {
  transition: all .2s;
  height: 52px;
  display: inline-flex;
  min-width: 230px;
  font-size: 100%;
  border: 1px solid var(--color-primary);
  font-weight: 900;
  color: var(--color-primary);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  box-shadow: 0 2px 6px rgba(var(--color-primary-rgb), 0);

  &[disabled] {
    opacity: .3;
    cursor: no-drop;
  }

  @media screen {
    &:hover {
      //background-color: #E42D42;
      box-shadow: 0 2px 6px rgba(var(--color-primary-rgb), .5);
    }
  }
}

.btn--secondary {
  height: 77px;
  font-size: 131.25%;
  font-weight: 900;
  text-transform: uppercase;
  min-width: 320px;
  background: #FFFFFF;
  border: 1px solid transparent;
  border-radius: 10px;
  box-shadow: 0 4px 4px var(--color-shadow);

  &[disabled] {
    background-color: var(--color-border);
    box-shadow: none;
  }

  @media screen {
    &:hover {
      border-color: var(--color-shadow);
    }
  }

  &-big {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 187.5%;
    letter-spacing: 1px;
    box-shadow: 0 4px 4px rgba(#000, .15);
    border: none;

    @media screen {
      &:hover {
        box-shadow: 0 4px 8px rgba(#000, .25);
        color: var(--color-primary);
      }
    }
  }


}

.btn--border {
  border: 1px solid var(--color-shadow);
}

.btn--c-primary {
  background-color: var(--color-primary);
  color: #fff;
}

.btn--c-secondary {
  background-color: var(--color-title);
  color: #fff;
}

.btn--circle {
  width: 42px;
  height: 42px;
  display: flex;
  border-radius: 52px;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 375px) and (max-width: 600px) {
    width: 32px;
    height: 32px;
  }

  @media screen and (max-width: 374px) {
    width: 27px;
    height: 27px;
  }

  svg {
    fill: currentColor;
    //width: 50%;
    //height: 50%;
  }

  &-nav {
    background-color: #fff;
    border: 1px solid var(--color-shadow);
    box-shadow: 0 4px 8px rgba(#000, 0);
    color: var(--color-title);

    &[data-disabled] {
      opacity: 0;
      visibility: hidden;
    }

    @media screen {
      &:hover {
        border-color: var(--color-shadow);
        box-shadow: 0 4px 8px rgba(#000, .15);
      }
    }
  }

  @media screen and (min-width: 801px) {
    border: 1px solid transparent;
    width: 52px;
    height: 52px;
  }
}

.btn--line {
  padding: 0 var(--offset-x);
  display: inline-flex;
  height: 52px;
  border-radius: var(--radius-l);
  font-weight: 600;
  color: var(--color-title);
  border: 1px solid var(--color-shadow);
  align-items: center;
  background-color: #fff;

  svg {
    margin-right: 14px;
  }

  @media screen {
    &:hover {
      box-shadow: 0 4px 4px var(--color-shadow);
    }
  }
}

.btn--easy {

}

.btn--easy-icon {
  height: 1em;
  font-size: 131.25%;
  font-weight: 700;
  align-items: center;
  padding: 0;
  color: var(--color-title);
  display: flex;

  svg {
    margin-left: 5px;
    fill: currentColor;
  }

  @media screen {
    &:hover {
      color: var(--color-primary);
    }
  }

}

.btn--time {
  display: flex;
  flex-direction: column;
  height: 60px;
  width: 100%;
  border-radius: var(--radius-l);
  border: 1px solid var(--color-shadow);
  justify-content: center;
  align-items: center;
  color: var(--color-title);
  
  &.-red-corner- {
	overflow: hidden;
	
	&:before {
	  content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      border-top: 60px solid red;
      border-right: 0 solid transparent;
      border-bottom: 0 solid transparent;
      border-left: 60px solid transparent;
    }
  }

  b {
    border-bottom: 1px solid var(--color-shadow);
    font-size: 87.5%;
    font-weight: 700;
    margin-bottom: 8px;
  }

  small {
    opacity: .6;
    transition: opacity .2s;
  }

  @media screen {
    &:hover {
      background-color: var(--color-title);
      color: #fff;
      border-color: var(--color-title);
      box-shadow: 0 4px 4px var(--color-shadow);

      small {
        opacity: 1;
      }
    }
  }

  @media screen and (min-width: 961px) {
    b {
      font-size: 112.5%;
    }
  }

}


.-fest-list {
	.card--easy:not(.is-reserve) {
		.btn--primary {
			background-color: #7ac900;
		}
	}
}


.label-3d {
  position: absolute;
  top: 5px;
  right: 5px;
  color: white;
  width: 25px;
  height: 25px;
  text-align: right;
}

.link--social {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: currentColor;
  }

  & + .link--social {
    margin-left: var(--offset-xm);

	@media screen and (max-width: 1199px) {
      margin-left: 16px;
    }

	@media screen and (max-width: 900px) {
      margin-left: var(--offset-xm);
    }
  }
}

li .link--social {
  width: 50px;
  height: 50px;

  svg {
      width: 50%;
	  height: 50%;
  }

  & + .link--social {
    margin-left: var(--offset-x);
  }
}

.btn--nav {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  height: 72px;
  color: #fff;
  font-size: 131.25%;

  svg {
    fill: currentColor;
    margin-right: var(--offset-x);
  }

  @media screen {
    &:hover {
      color: var(--color-primary);
    }
  }

}

.btn--event-tab {
  height: 52px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  border-bottom: 0;
  color: var(--color-text-light);
  width: 100%;
  font-size: 90%;

  @media screen and (min-width: 801px) {
    border-radius: 10px 10px 0 0;
    font-size: 100%;
  }

  &.-is-active- {
    font-weight: 700;
    color: var(--color-title);
    border-bottom: 3px solid var(--color-title);

    @media screen and (min-width: 801px) {
      border-color: var(--color-shadow);
      background-color: #fff;
      border-bottom: 1px solid var(--color-shadow);
    }
  }

  @media screen {
    &:hover {
      color: var(--color-title);
    }
  }
}
