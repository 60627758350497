body[data-mobile="false"] {

  .-slider- {
    &[data-type=multiple] {
      .btn--circle-nav {
        opacity: 1;
        visibility: visible;

        &[data-disabled] {
          opacity: 0;
          visibility: hidden;
        }

        &.-prev- {
          left: -9px;
        }

        &.-next- {
          right: -9px;
        }

        @media screen and (min-width: 801px) {
          top: -73px;

          &.-prev- {
            right: 73px;
            left: initial;
          }

          &.-next- {
            right: 9px;
          }
        }

        @media screen and (min-width: 1052px) {
          top: -83px;
        }
      }
    }
  }

  .section--slider-multiple {
    @media screen and (min-width: 801px) {
      .section--title {
        padding-right: 157px;
      }
    }
    @media screen and (min-width: 601px) and (max-width: 880px) {
      .-slider--thumbs- {
        max-width: 480px;
      }
    }
  }

  .cards--list {
	width: 100%;
    table-layout: fixed;
    .card--bellow,
    .card--abowe,
    .card--normal {
      margin: 0 var(--offset-xx);
    }
  }

  .quick-search-form {
    @media screen and (min-width: 1052px) {
      &[data-open='1'] input,
      input:focus {
        min-width: 400px;
        font-size: 150%;
        font-weight: 700;
      }
    }
  }

}
