.section-intro {
  padding: 0;
  background-color: var(--color-title) ;
  background-image: linear-gradient(-45deg, #2c022f 30%, #271c0b 45%, #2d0b0b 65%, #28022e 100%);
  //background-size: 400% 400%;
  //animation: gradient 5s ease infinite;
  color: #fff;
  //position: sticky;
  //z-index: 52;
  //will-change: top;
  //transition: top .2s;

  &--footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn--primary {
      padding: 0;

      @media screen and (min-width: 1052px) {
        padding: 0 var(--offset-m);
      }
      @media screen and (min-width: 501px) {
        padding: 0 var(--offset-s);
      }
      @media screen and (max-width: 500px) {
        width: 100%;
        font-size: 87%;
      }
    }

    @media screen and (max-width: 700px) {
      position: absolute;
      bottom: -200px;
      left: 16px;
      width: calc(100% - 32px);
      border: 1px solid var(--color-shadow);
      box-shadow: 0 2px 4px var(--color-shadow);
      padding: var(--offset-x);
      background-color: #fff;
      border-radius: var(--radius-l);
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &--top {
    margin-bottom: var(--offset-sx);
    display: none;

    .breadcrumbs {
      padding: 0;
    }

    @media screen and (min-width: 961px) {
      display: block;
    }
  }

  &--content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: var(--offset-s);
    margin-bottom: var(--offset-xs);

    .h1 {
      color: #fff;
      margin-bottom: 0;
      line-height: 1;
      width: fit-content;
      max-width: 880px;
      background: #011031;
      padding: 8px;
      border-radius: 10px;

      @media screen and (max-width: 700px) {
        font-size: 150%;

        &.mobile-hide {
          visibility: hidden;
        }
      }

    }
  }

  @media screen and (min-width: 1052px) {
    &--content {
      padding-top: 0;
      margin-bottom: 0;
    }

    &.-is-big- {
      //top: -324px;

      .section-intro--content {
        height: 300px;
      }
    }

    &.-is-normal- {
      //top: -224px;

      .section-intro--content {
        height: 200px;
      }
    }
  }

  &--address {
    padding-top: var(--offset-x);
    padding-right: var(--offset-ml);

    button {
      color: #fff;
      font-weight: 600;
      margin-right: var(--offset-x);
      font-size: 112.5%;
      height: 55px;

      @media screen {
        &:hover {
          color: var(--color-primary);
        }
      }
    }

    @media screen and (min-width: 701px) {
      padding-right: 0;
    }
  }

  &--subscribe {
    display: flex;
    align-items: center;
    width: 100%;

    button {
      flex-shrink: 0;

      & + button {
        margin-left: var(--offset-xx);

        @media screen and (min-width: 801px) {
          margin-left: var(--offset-x);
        }
      }
    }

    @media screen and (min-width: 701px) {
      width: auto;
    }

    .btn-ss-fave {
      color: #fff;

      @media screen and (max-width: 700px) {
        position: absolute;
        top: -96px;
        right: 0;
      }
    }

    .btn-ss-share {
      color: var(--color-text-light);

      @media screen and (min-width: 701px) {
        color: #fff;
      }

      @media screen and (max-width: 500px) {
        position: absolute;
        top: 76px;
        right: 18px;
      }
    }

    .btn--rect {
      border-radius: 10px;

      @media screen and (min-width: 1052px) {
        &:hover {
          background-color: rgba(var(--color-primary-rgb), .6);
        }
      }
    }

  }

  &--subscribers {
    display: flex;
    align-items: center;
    min-height: 42px;

    order: 2;
    margin-top: 16px;
    pointer-events: none;

    div {
      color: var(--color-text-light);
    }

    a {
      margin-right: 8px;
      border-radius: 55px;
      background-color: rgba(#000, .2);

      @media screen and (min-width: 300px) and (max-width: 400px) {
        &:nth-child(3) ~ a {
          display: none;
        }
      }
      @media screen and (min-width: 701px) and (max-width: 900px) {
        &:nth-child(3) ~ a {
          display: none;
        }
      }
    }

    a,
    div {
      height: 36px;
      width: 36px;
      flex-shrink: 0;

      @media screen and (min-width: 781px) {
        height: 55px;
        width: 55px;
      }
    }

    img,
    div {
      border-radius: 55px;
    }

    div {
      color: var(--color-text-light);
      box-shadow: inset 0 0 0 1px currentColor;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 700;
    }

    @media screen and (min-width: 701px) {
      order: 0;
      margin-top: 0;

      div {
        color: #fff;
      }
    }
  }
}

.-sif- {
  color: #fff;
  display: flex;
  align-items: center;
  //position: sticky;
  //z-index: 2;
  padding: var(--offset-xx) 0;
  //will-change: top;
  //transition: top .2s;
  justify-content: center;
  flex-direction: column-reverse;

  @media screen and (min-width: 1000px) {
    justify-content: flex-start;
    flex-direction: row;
    overflow: hidden;
  }

  &-div- {
    height: var(--offset-xs);
  }

  /*.-pinned-name- {
    overflow: hidden;
    white-space: nowrap;
    padding: 8px 0;
    font-size: 112.5%;
    width: 100%;
    text-overflow: ellipsis;
    font-weight: 700;
    opacity: 0;
    transition: all .2s;
  }

  &.-pinned- {
    box-shadow: 0 2px 4px rgba(#000, .15);

    .-pinned-name- {
      opacity: 1;
    }
  }*/

  .btn--rect {
    color: #fff;
    flex-shrink: 0;
    margin-left: var(--offset-xx);
    background-color: #011031;
    border-radius: 10px;

    & + .btn--rect {
      margin-left: 0;
    }

    @media screen and (min-width: 375px) {
      & + .btn--rect {
        margin-left: var(--offset-xx);
      }
    }
    @media screen and (min-width: 1152px) {
      margin-left: var(--offset-x);
    }

    @media screen {
      &:hover {
        color: var(--color-primary);
      }
    }
  }

  &-nav- {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    max-width: 0;
    max-height: 0;
    overflow: auto hidden;
    scrollbar-width: none; /* Для Firefox */
    -ms-overflow-style: none; /* Для Internet Explorer и Edge */
    max-width: 100%;
    max-height: initial;
    flex-wrap: nowrap;
    margin-top: 10px;

    &::-webkit-scrollbar {
      display: none; /* Для Chrome, Safari и Opera */
  }

    @media screen and (min-width: 1000px) {
      max-width: 50%;
      flex-wrap: wrap;
      overflow: hidden;
      margin-top: 0;
      gap: 16px;
    }

    button {
      font-size: 100%;
      height: 28px;
      color: #011031;
      font-weight: 600;
      text-wrap: nowrap;
      white-space: nowrap;
      outline: none;

      @media screen and (min-width: 1000px) {
        height: 36px;
      }
  

      @media screen {
        &:hover {
          color: var(--color-primary);
          scale: 1.03;
        }
      }
    }

  }

  &-form- {
    position: relative;
    flex-shrink: 0;

    .btn--primary {
      white-space: nowrap;
      font-size:60%;
      height: 32px;
      padding: 0 5px;
      min-width: auto;
    }

    @media screen and (min-width: 801px) {
      .btn--primary {
        font-size: 100%;
        padding: 0 var(--offset-sx);
        height: 52px;
      }
    }
    @media screen and (min-width: 1000px) {
      margin-left: auto;

      .btn--primary {
        font-size: 112%;
      }
    }
  }

  //@media screen and (min-width: 1052px) {
  //  top: 0;
  //}

}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.section-intro-artist {
  background-color: var(--color-title) ;
  background-image: var(--custom-background);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  .-sif--nav- {
    align-items: center;
  }
}

.tablinks {
  font-weight: 600 !important;
  display: flex;
  align-items: center;
  color: #011031;
  background: var(--color-bg);
  padding: 0 8px;
  border-radius: 10px;
  // transition: none;
}

.tablinks.active {
  color: var(--color-primary);
}

.artist-tab-content {
  margin-top: 36px;

  &.artist-tab-content--not-margin {
    margin-top: 0;
  }
}

.artist-tab-content-popular {
  position: relative;
}

.artist-bio-grid {
  display: grid;
  grid-template-columns: max-content auto;
  font-size: 16px;
  line-height: 1;
  color: #666C7D;
}

.artist-bio-grid div:nth-last-child(-n+2) {
  border: none;
}

.bio-title-name {
  border-bottom: 1px solid #666C7D;
  padding: 10px 30px 10px 0;
  font-weight: 600;
    @media screen and (min-width: 601px) {
      & {
        padding: 10px 50px 10px 0;
      }
    }

    @media screen and (min-width: 1001px) {
      & {
        padding: 10px 80px 10px 0;
      }
    }
  }



.bio-title {
  border-bottom: 1px solid grey;
  padding: 10px 0;
}

.bio-social {
  display: inline-block;
  font-size: 0;
  width: 16px;
  height: 16px;
  color: #011031;

  svg {
    fill: currentColor;
  }

  &:hover {
    color: var(--color-primary);
  }
}

.bio-social.bio-social-icon-youtube_com {
  width: 24px;
  svg {
    width: 24px;
    height: 16px;
  }
}

.bio-social:not(:last-child) {
  margin-right: 16px;
}

.artist-popular-wrap {

}

.artist-popular-item {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1;
  color: #666C7D;
  border-bottom: 1px solid #666C7D;
  padding: 10px 0;

  &.active {
    .artist-popular-item-number,
    .artist-popular-item-play,
    .artist-popular-item-name {
      color: var(--color-primary);
    }
  }
}

.artist-popular-item-number {
  min-width: 16px;
  margin-right: 20px;
}

.artist-popular-item-play {
  margin-right: 20px;
  cursor: pointer;

  svg * {
    fill: currentColor;
  }
  &:hover {
    color: var(--color-primary);
  }
}

.-artist-popup-video- {
  z-index: 1000;
  position: fixed;
  width: 44%;
  // min-width: 600px;
  border-radius: 10px;
  padding-bottom: 25.5%;
  right: 100px;
  bottom: 20px;
  @media screen and (max-width: 1590px) {
    // padding-bottom: 450px;

  }

  @media screen and (max-width: 900px) {
      min-width: 95%;
      padding-bottom: 53.4%;
      right: 50%;
      transform: translate(50%);
  }

  .-video-youtube- {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
  }

  .info-artist-popup__toggle {
    top: -40px;
    right: 20px;

    @media screen and (max-width: 900px) {
        top: -40px;
        right: 10px;
    }
  }
}

.info-artist-popup__toggle {
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: #273d71;
  border-radius: 50%;
  position: absolute;
  right: 40px;
  top: 15px;
  opacity: 0.5;
  z-index: 1000;
}

.info-artist-popup__toggle:hover {
  opacity: 1;
}

.info-artist-popup__toggle::before, .info-artist-popup__toggle::after {
  position: absolute;
  left: 14px;
  content: ' ';
  height: 15px;
  width: 2px;
  background-color: #fff;
}

.info-artist-popup__toggle::before {
  transform: rotate(45deg);
}

.info-artist-popup__toggle::after {
  transform: rotate(-45deg);
}

.artist-album-grid {
  display: grid;
  row-gap: 32px;
  column-gap: 20px;
  justify-content: center;

  @media (max-width: 500px) {
      grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: 501px){
      grid-template-columns: repeat(2, 1fr);
  }
  
  @media (min-width: 751px) {
      grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1001px) {
      grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1251px) {
      grid-template-columns: repeat(5, 1fr);
  }
}

.artist-album-item {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 500px) {
    flex-direction: row;
    // width: 50%;
  }
}

.artist-album-image {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #011031;

  &:hover {
    .artist-album-item-play {
      opacity: 1;
    }
  }

  img {
    display: block;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    max-width: 150px;

    @media (min-width: 400px) {
      max-width: 200px;
    }

    @media (min-width: 500px) {
      max-width: 100%;
    }
  }
}

.artist-album-description {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 1;
  color: #666C7D;

  @media (max-width: 500px) {
    margin-left: 16px;
  }
}

.artist-album-title {
  font-weight: 600;
  margin: 12px 0 4px;
}

.artist-album-item-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  cursor: pointer;

  svg {
    fill: var(--color-primary);
  }
}

.-sif--nav-js {
  @media (max-width: 740px) {
    &.left-shadow {
      box-shadow: -10px 0 5px -5px rgba(238, 66, 86, 0.8);
    }
  
    &.right-shadow {
      box-shadow: 10px 0 5px -5px rgba(238, 66, 86, 0.8);
    }
  
    &.left-shadow.right-shadow {
      box-shadow: -10px 0 5px -5px rgba(238, 66, 86, 0.8), 10px 0 5px -5px rgba(238, 66, 86, 0.8);
    }
  }
}