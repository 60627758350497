.checkbox {
  label {
    color: var(--color-text-light);
    line-height: 1.3;
    position: relative;
    padding: 0 21px 0 52px;
    transition: color .2s;
    display: block;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      top: 0;
      left: 21px;
      border-radius: var(--radius-x);
      border: 1px solid var(--color-shadow);
      transition: all .2s;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 5px;
      height: 10px;
      border: 2px solid #fff;
      border-left: 0;
      border-top: 0;
      left: 29px;
      top: 4px;
      transform: rotate(45deg);
      opacity: 0;
      transition: opacity .2s;
    }

    @media screen {
      &:hover {
        color: var(--color-text);

        &:before {
          box-shadow: 0 2px 2px var(--color-shadow);
        }
      }
    }
  }

  input {
    //width: 0;
    //height: 0;
    //appearance: none;
    //margin: 0;
    //overflow: hidden;
    //padding: 0;
    display: none;

    &:checked + label {
      color: var(--color-text);
      &:before {
        background-color: var(--color-title);
        border-color: var(--color-title);
      }
      &:after {
        opacity: 1;
      }
    }
  }

  & + .checkbox {
    margin-top: var(--offset-x);
  }

}