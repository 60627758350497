@import "card-normal";
@import "card-bellow";
@import "card-long";
@import "card-abowe";
@import "card-big";
@import "card-line";
@import "card-single-event";
@import "card-alphabet";

.cards {

  &-list--links {
    li {
      display: block;
      position: relative;
      padding-left: var(--offset-x);
      font-weight: 700;

      & + li {
        margin-top: var(--offset-xx);

        @media screen and (min-width: 961px) {
          margin-top: var(--offset-x);
        }
      }
    }
    a {
      color: var(--color-text-light);
      line-height: 1.35;

      svg {
        fill: currentColor;
        position: absolute;
        top: 4px;
        left: 0;
      }

      @media screen {
        &:hover {
          color: var(--color-primary);
        }
      }
    }

    @media screen and (min-width: 801px) {
      li {
        font-size: 112.5%;
      }
      a svg {
        top: 5px;
        width: 14px;
        height: 14px;
      }
    }
  }

  &--list {

    &-footer {
      padding-top: var(--offset-xx);

      @media screen and (min-width: 601px) {
        padding-top: var(--offset-s);
      }
      @media screen and (min-width: 1052px) {
        padding-top: var(--offset-m);
      }
    }

    .card--bellow,
    .card--abowe,
    .card--normal {
      margin: var(--offset-x) var(--offset-xx) 0;

      @media screen and (min-width: 601px) {
        margin-top: 0;
      }
    }

    .card--long {
      margin-top: var(--offset-x);

      @media screen and (min-width: 701px) {
        margin-top: var(--offset-s);
      }
    }

    &-long {
      .card--bellow {
        max-height: 140px;

        @media screen and (min-width: 601px) {

        }
      }
    }

    &.-season- {
      margin-left: -9px;
      margin-right: -9px;

      .card {
        margin-top: var(--offset-xs) !important;
        min-height: 180px;
        // padding-bottom: var(--offset-x);
        height: initial;

        &--price {
          font-weight: 600;
        }
        &--title {
          margin-bottom: var(--offset-xs);
        }
      }
      .btn--primary {
        max-width: 260px;
        min-width: initial;
        margin-top: var(--offset-x);
      }
    }
  }

  &--list-top {
    display: flex;
    flex-wrap: wrap;

    @media screen and (min-width: 961px) and (max-width: 1440px) {
      .card {
        flex-shrink: 0;

        &:nth-of-type(1),
        &:nth-of-type(2) {
          width: calc(50% - 9px);
        }

        &:nth-of-type(2) {
          margin-left: 18px;
        }

        &:nth-of-type(3) {
          width: 100%;
          margin-top: 18px;
        }

        &:nth-of-type(4),
        &:nth-of-type(5) {
          display: none;
          //width: calc(50% - 9px);
          //margin-top: 18px;
        }

      }
    }

    @media screen and (min-width: 1441px) {
      .card {
        flex-shrink: 0;

        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3) {
          width: calc((100% - 36px) / 3);
        }

        &:nth-of-type(4),
        &:nth-of-type(5) {
          width: calc(50% - 9px);
          margin-top: 18px;
        }

        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(5) {
          margin-left: 18px;
        }
      }
    }

  }

  &--artists {
    display: flex;
    flex-wrap: wrap;

    .card {
      max-height: 140px;
      margin-bottom: var(--offset-x);

      &--content {
        padding: var(--offset-x);
      }
      &--title {
        font-size: 100%;
        margin: 0;
      }

      @media screen and (min-width: 661px) and (max-width: 1151px){
        width: calc((100% - var(--offset-x)) / 2);

        &:nth-child(2n) {
          margin-left: var(--offset-x);
        }
      }

      @media screen and (min-width: 1152px){
        width: calc((100% - var(--offset-s)) / 3);

        &:nth-child(3n+2) {
          margin: 0 var(--offset-x) var(--offset-x);
        }
      }
    }
  }

}

.cards--list-footer {
  a,
  button {
    height: 44px;
    font-size: 87.5%;
    font-weight: 900;
    text-transform: uppercase;
    border: 1px solid transparent;
    border-radius: 10px;
    background-color: var(--color-bg);
    min-width: 260px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 601px) {
      height: 77px;
      font-size: 131.25%;
      min-width: 320px;
      background-color: #FFF;
      box-shadow: 0 4px 4px var(--color-shadow);
    }
  }
}

.card {
  background-color: #fff;

  &--data {
    //padding-bottom: 18px;

    .style-content {
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      will-change: padding, max-height, opacity;
      transition: all .2s;
    }

    [data-season-detail] {
      span {
        display: none;

        &:first-child {
          display: block;
        }
      }
    }
    &[data-view="show"] {
      padding-bottom: 12px;

      .style-content {
        max-height: initial;
        overflow: initial;
        opacity: 1;
        padding-bottom: 9px;
      }

      [data-season-detail] {
        span {
          display: block;

          &:first-child {
            display: none;
          }
        }
      }
    }
  }

  @media screen {
    transition: all .2s;

    &:hover {
      box-shadow: 0 4px 4px var(--color-shadow);
    }
  }
}

