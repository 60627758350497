.footer .section {
	&--row {
		align-items: normal;
	}
  }
.main-content{
  margin-left: 50px;
}

.main-content.main-content-artists {
  margin-left: 0;
  width: 100%;
}

.section {
  padding: var(--offset-x) 0;

  &--sliders {
    padding: 0;
  }

  &--header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: var(--offset-x);
    flex-direction: column;
    padding-top: var(--offset-xx);

    .h1 {
      margin-bottom: 0;
    }

    &-search {
      position: sticky;
      top: -56px;
      background-color: var(--color-bg);
      z-index: 1;
      padding-top: var(--offset-xs);
    }

    @media screen and (min-width: 801px) {
      flex-direction: row;
      align-items: center;
      padding-top: 0;
      margin-bottom: var(--offset-s);

      &-search {
        top: initial;
        background-color: transparent;
        position: static;
      }
    }

    @media screen and (min-width: 961px) {
      &-search {
        padding-top: 0;
      }
    }
  }

  &--title {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 112.5%;
    margin-bottom: var(--offset-x);

    a {
      color: currentColor;
      border-bottom: 1px solid;

      @media screen {
        &:hover {
          color: var(--color-primary);
        }
      }
    }

    &-secondary {
      color: var(--color-title);
      margin-bottom: var(--offset-x);
      font-size: 112.5%;
      font-weight: 900;

      @media screen and (min-width: 601px) {
        font-size: 150%;
        margin-bottom: var(--offset-s);
      }
      @media screen and (min-width: 801px) {
        font-size: 200%;
        font-weight: 700;
      }
      @media screen and (min-width: 1052px) {
        font-size: 250%;
      }
    }

    @media screen and (max-width: 600px) {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding: 12px;
      text-align: center;
      border-radius: 10px 10px 0 0;
      color: var(--color-bg);
      background-color: var(--color-title);
      border-bottom: 1px solid currentColor;
      box-shadow: 0 1px var(--color-shadow);
      line-height: 1.2;

      a {
        margin-left: 0.5em;
      }
    }

    @media screen and (min-width: 601px) {
      color: var(--color-title);
      font-size: 250%;
      margin-bottom: var(--offset-xs);
    }
    @media screen and (min-width: 1052px) {
      margin-bottom: var(--offset-s);
    }
  }

  &--row {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
  }

  &--2 {
    flex-shrink: 0;

    @media screen and (min-width: 601px) {
      width: calc(50% - 9px);

      & + .section--2 {
        margin-left: var(--offset-x);
      }
    }
    @media screen and (max-width: 600px) {
      width: 100%;

      & + .section--2 {
        margin-top: var(--offset-s);
      }
    }
  }

  @media screen and (min-width: 601px) {
    padding: var(--offset-sm) 0;
  }
  @media screen and (min-width: 801px) {
    padding: var(--offset-m) 0;
  }

  &--auth  {
    .container{
       @media screen and (max-width: 358px) {
        padding: 0 2%;
      }
    }

    .form--group {
       @media screen and (max-width: 358px) {
        margin-bottom: 24px;
      }
    }
  }
}

.section--content {
  @media screen and (max-width: 600px) {
    margin-top: calc(1px - var(--offset-x));
    border: 1px solid var(--color-shadow);
    border-top: 0;
    padding: var(--offset-x) var(--offset-x) var(--offset-xs);
    background-color: #fff;
    border-radius: 0 0 var(--radius-l) var(--radius-l);
  }
}

.season {
  &--back-link {
    margin-left: var(--offset-xs);
    position: relative;
    font-size: 100%;

    svg {
      fill: currentColor;
      position: absolute;
      top: 50%;
      transform: translate(-24px, -50%);
    }
  }

  &--area {
    padding-top: var(--offset-x);
  }

  &--header {
    padding: var(--offset-xx) 0;
    border-bottom: 1px solid var(--color-shadow);
  }

  &--nav {
    padding: var(--offset-x) 0;
    display: flex;
    align-items: center;

    svg {
      margin-right: var(--offset-xx);
    }

    .stage-name {
      margin-right: var(--offset-x);
    }
  }

  &--select {
    flex-shrink: 0;
    width: 120px;
    position: relative;
    border-radius: var(--radius-l);
    background-color: #fff;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      right: 12px;
      width: 5px;
      height: 5px;
      border: 2px solid var(--color-text);
      border-left: none;
      border-top: none;
      transform: translate(0, -70%) rotate(45deg);
      z-index: 1;
    }

    &.-days- {
      margin-left: auto;
    }

    @media screen and (min-width: 801px) {
      margin-left: initial;
    }

    select {
      font-size: 100%;
      width: 100%;
      position: relative;
      z-index: 2;
      background-color: transparent;
      appearance: none;
      border: 1px solid var(--color-shadow);
      height: 36px;
      border-radius: var(--radius-l);
      padding: 0 var(--offset-x) 0 var(--offset-xx);
    }
  }

  .profile--order-event-body {
    min-height: 24px;
    max-width: initial;

    aside {
      padding-top: var(--offset-xs);

      button {
        font-size: 87%;
        color: var(--color-text);
        border-bottom: 1px dashed currentColor;

        & + button {
          margin-left: var(--offset-xx);
        }

        @media screen {
          &:hover {
            color: var(--color-primary);
          }
        }
      }
    }
  }

  .card--easy {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: var(--offset-x);
    border-radius: var(--radius-l);
    flex-wrap: wrap;
    padding: var(--offset-xx);

    @media screen and (max-width: 801px) {
      min-height: 138px;
    }

    .h5 {
      line-height: 1.2;
    }

    img {
      border-radius: var(--radius-l);
      margin-right: var(--offset-x);
    }

    .btn--primary {
      width: 100%;
      max-width: 180px;
      min-width: initial;
      margin-left: 67px;
      margin-bottom: 9px;
      max-height: 40px;
      font-size: 87%;

      @media screen and (min-width: 801px) {
        margin-left: auto;
        margin-bottom: 0;
      }
    }

    &-time {
      display: flex;
      align-items: end;
      margin-bottom: 4px;

      svg {
        margin-right: var(--offset-xx);
      }

      @media screen and (min-width: 801px) {
        margin-bottom: 0;
      }
    }
    &-body {
      padding: var(--offset-xx) 0;
      width: calc(100% - 70px);
      flex-shrink: 0;

      @media screen and (min-width: 801px) {
        width: calc(100% - 180px - 70px);
      }
    }

    .season-error {
      width: 100%;
      flex-shrink: 0;
      padding: var(--offset-xx) var(--offset-x);
      color: var(--color-primary);
      text-align: center;
    }
  }
}
