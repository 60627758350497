@font-face {
  font-family: 'Proxima Nova';
  src: url('../../assets/fonts/ProximaNova-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../assets/fonts/ProximaNova-Semibold.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../assets/fonts/ProximaNova-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../assets/fonts/ProximaNova-Extrabold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../assets/fonts/ProximaNova-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
