.card-event {

  &--detail-cinema {
    color: var(--color-text-light);
    margin-bottom: var(--offset-s);
    margin-top: auto;
    margin-left: -76px;

    dl {
      display: flex;

      & + dl {
        margin-top: var(--offset-x);
      }
    }
    dt {
      width: 120px;
      flex-shrink: 0;

      &.iframe-video--mob {
        @media screen and (min-width: 481px) {
          display: none;
        }
      }

      &.text-trailer {
        padding-top: 15px;
      }
    }
    dd {
      flex-grow: 1;
      margin-left: var(--offset-x);

      &.iframe-video--desktop {
        @media screen and (max-width: 480px) {
          display: none;
        }
      }
    }

    .-date- {
      line-height: 16px;
      font-weight: 700;
      font-size: 75%;
      color: #fff;
      display: inline-block;
      vertical-align: top;
      padding: 0 8px;
      background-color: var(--color-primary);
      border-radius: var(--radius-x);
    }

    @media screen and (min-width: 601px) {
      margin-left: -116px;
    }

    @media screen and (min-width: 801px) {
      margin-left: 0;
    }

    .wrapper-play {
      width: 48px;
      height: 48px;
      font-size: 48px;

      .player {
        fill: #666c7d;
      }
      &:hover  {
        cursor: pointer;
        .player {          
          fill: #011031;
        }
      }
    }
  }

  &--description {
    color: var(--color-text-light);
    line-height: 1.75;
    margin-left: -76px;

    @media screen and (min-width: 601px) {
      margin-left: -116px;
    }

    @media screen and (min-width: 801px) {
      margin-left: 0;
    }
  }

  &--body {
    display: flex;
    align-items: flex-start;
    padding: var(--offset-s) 0 0;

    &-main {
      padding: var(--offset-s) 0;

      @media screen and (min-width: 801px) {
        padding: var(--offset-s);
        border-radius: var(--radius-l);
        box-shadow: 0 1px 4px var(--color-shadow);
        background-color: #fff;
      }
      @media screen and (min-width: 961px) {
        padding: var(--offset-s) 60px;
      }
    }

    &-content {
      flex-grow: 1;
      max-width: 100%;

      @media screen and (min-width: 961px) {
        &.-with-aside- {
          max-width: calc(100% - 300px - var(--offset-sx));
        }
      }

      @media screen and (min-width: 1441px) {
        &.-with-aside- {
          max-width: calc(100% - 420px - var(--offset-s));
        }
      }


      .section--event {
        padding-top: var(--offset-m);
        margin-bottom: 0;

        h2, .h2 {
          padding-top: var(--offset-xs);
          margin-bottom: .75em;
          font-size: 150%;

          @media screen and (min-width: 961px) {
            padding-top: var(--offset-s);
            font-size: 175%;
          }
          @media screen and (min-width: 1281px) {
            font-size: 200%;
          }
        }

        .-box-photo-,
        .-box-video- {
          margin-bottom: var(--offset-x);

          @media screen and (min-width: 961px) {
            margin-bottom: var(--offset-s);
          }
        }

        & > *:last-child {
          margin-bottom: 0;
        }

        & > *:first-child {
          padding-top: 0;
        }

        &:empty {
          padding: 0;
        }
      }
      .section--faq {
        padding-top: var(--offset-m);
        margin-bottom: 0;
		p {
			font-size: 16px;
		}

        h2, .h2 {
          padding-top: var(--offset-xs);
          margin-bottom: .75em;
          font-size: 150%;

          @media screen and (min-width: 961px) {
            padding-top: var(--offset-s);
            font-size: 175%;
          }
          @media screen and (min-width: 1281px) {
            font-size: 200%;
          }
        }
		.question-item {
			margin-bottom: 15px;
		}
		.question-item__title {
			font-weight: bold;
			font-size: 16px;
			margin-bottom: 5px;
		}
		.question-item__body {
			margin-bottom: 0;
		}
      }

    }

    &-aside {
      position: sticky;
      top: var(--offset-s);
      max-height: 0;
      max-width: 0;
      flex-shrink: 0;
      overflow: hidden;
      opacity: 0;
      will-change: transform;
      transition: transform .2s;

      & > b {
        margin-bottom: var(--offset-x);
        display: block;
        line-height: 1.5;
        padding-bottom: var(--offset-x);
        border-bottom: 1px solid var(--color-border);
      }

      .card-event--detail-select {
        margin-right: 0;
      }

      .card-event--detail-line {
        & > svg {
          display: none;
        }
        .btn--primary {
          margin-left: 0;
        }

        &:nth-of-type(3) {
          margin-top: 0;
          margin-bottom: -18px;
          padding: var(--offset-x) 0;
        }
      }

      @media screen and (min-width: 961px) {
        max-height: initial;
        overflow: initial;
        opacity: 1;
        width: 300px;
        max-width: initial;
        margin-left: var(--offset-sx);
        padding: var(--offset-x);
      }

      @media screen and (min-width: 1441px) {
        width: 420px;
        margin-left: var(--offset-s);
      }

    }
  }

  &--nav {
    position: sticky;
    top: 0;
    background-color: var(--color-bg);
    display: flex;
    z-index: 5;
    margin-left: calc(-1 * 100% / 18 );
    padding-left: 5%;
    width: calc(100% + 100% / 9);
    margin-bottom: var(--offset-xs);
    margin-top: calc(-1 * var(--offset-x));

    li {
      margin-bottom: -1px;

      & + li {
        margin-left: var(--offset-x);
      }
    }


    @media screen and (min-width: 801px) {
      padding-left: 0;
      width: 100%;
      margin-left: 0;

      li {
        width: 25%;
        flex-grow: 1;
        margin-left: 0;
      }
    }



    &-single {
      position: sticky;
      top: 0;
      background-color: var(--color-title);
      z-index: 5;
      margin-left: calc(-1 * 100% / 18 );
      width: calc(100% + 100% / 9);
      padding: 12px 0;
      will-change: transform;
      transition: transform .2s;

      @media screen and (min-width: 961px) {
        padding: 12px 12px ;
      }

      &-scroll {
        width: 100%;
        display: flex;
        padding: 0 0 0 4%;
        height: 68px;
        overflow-x: auto;
        overflow-y: hidden;
        overscroll-behavior-x: none;
        scroll-behavior: smooth;
        
        @media screen and (min-width: 801px) {
          padding-left: 0;
        }

        &:after {
          content: '';
          display: flex;
          width: 1px;
          height: 88px;
          background-color: transparent;
          vertical-align: top;
          flex-shrink: 0;
        }
      }
      & > div {
        height: 40px;
        overflow: hidden;
      }
      & > div > div {


      }

      button {
        white-space: nowrap;
        height: 40px;
        flex-shrink: 0;
        padding: 0 var(--offset-x);
        border: 1px solid transparent;
        border-radius: var(--radius-l);
        font-size: 87.5%;
        margin-right: var(--offset-x);
        font-weight: 600;
        color: #fff;

        &.-is-active- {
          border-color: var(--color-shadow);
          color: var(--color-title);
          background-color: #fff;
        }

        @media screen and (max-width: 511px) {
          max-width: 49%;
          white-space: break-spaces;
          padding: 0 1%;
          margin-right: 1%;
        }

      }

      @media screen and (min-width: 801px) {
        background-color: var(--color-bg);
        margin-left: calc(-1 * var(--offset-x));
        padding: 12px var(--offset-x);
        width: calc(100% + var(--offset-s));
        margin-bottom: var(--offset-xx);

        & > div {
          border-bottom: 1px solid var(--color-shadow);
        }

        ul {
          padding-left: 0;
          height: initial;
          overflow-y: initial;
        }

        button {
          border-radius: var(--radius-l) var(--radius-l) 0 0;
          color: var(--color-text);
        }

        li {
          margin-bottom: -1px;
          width: 25%;
          flex-grow: 1;
          margin-left: 0;

          & + li {
            margin-left: var(--offset-x);
          }
        }

      }
    }

  }

  &--tags {
    line-height: 1.75;
    font-weight: 700;
    color: var(--color-text-light);

    a {
      color: currentColor;

      @media screen {
        &:hover {
          color: var(--color-primary);
        }
      }

    }

    .card--d {
      margin: 0 8px;
    }

  }

}

.card-event {
  display: flex;
  border-radius: var(--radius-l);
  padding-top: var(--offset-x);

  .h1 {
    margin-bottom: var(--offset-x);

	span {

		font-size: 50%;
		color: var(--color-text-light);
		display: block;
	}
	i {
		display:none;
	}
  }

  &--head {
    display: flex;
    justify-content: space-between;

    .card--fave {
      margin-left: var(--offset-xx);
      flex-shrink: 0;
    }

  }

  &--thumb {
    margin-right: 16px;
    flex-shrink: 0;

    img {
      display: block;
      border-radius: var(--radius-l);
      object-fit: cover;
      object-position: center;
      height: 100px;
      width: 70px;
    }
  }

  &--content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: calc(100% - 86px);
  }

  &--notice {
    font-weight: 700;
    color: var(--color-primary);
    line-height: 1.35;
    word-break: break-all;
    margin-top: -9px;
    margin-bottom: 18px;
    padding: 0 var(--offset-xx);
    width: 100%;

    &.-pad- {
      padding: 0 var(--offset-x);
    }
  }

  &--detail {
    display: flex;
    margin-top: auto;
    margin-bottom: 0;
    flex-direction: column;
    position: relative;

    &-select {
      flex-grow: 1;
      max-width: 480px;

      .dropdown {
        flex-grow: 1;
      }
    }

    &-share {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      padding: var(--offset-xs) 0 var(--offset-x);

      .dropdown {

        &--trigger-item {
          font-weight: 600;
          font-size: 100%;
          color: var(--color-text-light);
        }

        & + .dropdown {
          margin-top: 9px;
        }
      }

      .dropdown--trigger-item__name{
        padding-bottom: 4px;
        border-bottom: 1px solid var(--color-text-light);
      }

      .dropdown--popup {
        flex-direction: column;
        align-items: flex-start;
        padding: var(--offset-x);
        margin-top: 6px;

        button {
          color: var(--color-title);
          text-align: left;
          min-width: 160px;

          svg {
            fill: currentColor;
            margin-right: 6px;
          }

          & + a,
          & + button {
            margin-top: 9px;
          }
        }
      }

      @media screen {
        button:hover {
          color: var(--color-primary);
        }
      }

      @media screen and (min-width: 481px) {
        flex-direction: row;
      }
      @media screen and (min-width: 801px) {
        align-self: stretch;
        //flex-direction: column;
        padding: 14px 0;
        align-items: flex-end;
      }
      @media screen and (min-width: 1281px) {
        padding: 8px 0 10px;
      }
    }

    &-line {
      display: flex;
      align-items: center;
      min-height: 52px;

      &.-wrap- {
        flex-wrap: wrap;
      }

      &.-mb- {
        margin-bottom: var(--offset-x);
      }

      &.-mt- {
        margin-top: var(--offset-x);
      }

      & > svg {
        z-index: 1;
        margin: 0 -20px 0 0;
        fill: var(--color-text-light);
      }

      .-text- {
        font-weight: 700;
        padding-left: 38px;
        line-height: 1.2;

        @media screen and (min-width: 601px) {
          b {
            font-size: 112.5%;
          }
        }
      }

      &.-pad- {
        & > svg {
          margin-left: 18px;
        }
      }

      .btn--primary {
        flex-grow: 1;

        &[disabled] {
          pointer-events: none;
          opacity: .3;
        }
      }

      .dropdown--normal {
        width: 100%;
        margin-left: -18px;

        .dropdown--trigger-item {
          padding-left: 56px;
        }
      }

    }

    @media screen and (min-width: 801px) {
      flex-direction: row;
    }

  }

  @media screen and (min-width: 601px) {
    padding-top: var(--offset-s);

    &--thumb {
      img {
        height: 140px;
        width: 100px;
      }
    }

    &--content {
      max-width: calc(100% - 116px);
    }


  }

  @media screen and (min-width: 801px) {

    &--head {
      .card--fave {
        margin-left: var(--offset-x);
      }
    }

    &--thumb {
      margin-right: var(--offset-s);

      img {
        height: 210px;
        width: 145px;
      }
    }

    &--content {
      max-width: calc(100% - 181px);
    }

  }

  @media screen and (min-width: 961px) {
    padding: var(--offset-sx);
    border-radius: var(--radius-l);
    border: 1px solid var(--color-shadow);
    background-color: #fff;

    &--thumb {
      img {
        height: 430px;
        width: 310px;
      }
    }

    &--content {
      max-width: calc(100% - 336px);
    }

  }

  @media screen and (min-width: 1052px) {
    padding: var(--offset-s);
  }

  @media screen and (min-width: 1281px) {
    padding: var(--offset-s) var(--offset-ml);
  }

}

.card-event--cinema {
  margin-bottom: var(--offset-x);

  .card-event {

    &--head {
      min-height: 116px;
      align-items: flex-start;

      @media screen and (min-width: 601px) {
        min-height: 158px;
      }

      @media screen and (min-width: 801px) {
        min-height: initial;
        align-items: center;
      }
    }

  }

  @media screen and (min-width: 961px) {
    margin-bottom: 0;
  }

}

.card-event--normal {
  .card-event {
    &--detail-share {
      .link--btn {
        font-size: 75%;
        white-space: nowrap;

        @media screen and (min-width: 374px) {
          font-size: 87.5%;
        }
        @media screen and (min-width: 1152px) {
          font-size: 100%;
        }
        @media screen and (min-width: 1281px) {
          font-size: 112%;
        }
      }
    }
    &--detail-select {
      @media screen and (min-width: 801px) {
        margin-right: var(--offset-x);
      }
      @media screen and (min-width: 1152px) {
        margin-right: var(--offset-s);
      }
      @media screen and (min-width: 1281px) {
        margin-right: var(--offset-m);
      }
    }
    &--head {
      margin-bottom: var(--offset-x);
      min-height: 116px;

      h1 {

        font-weight: 900;
        color: var(--color-title);
        font-size: 131.25%;
        line-height: 1.2;
        margin: 0;
		
		span {

			font-size: 50%;
			color: var(--color-text-light);
			display: block;
		}
		i {
			display:none;
		}
      }

      @media screen and (min-width: 601px) {
        min-height: initial;
        h1 {
          font-size: 200%;
        }
      }

      @media screen and (min-width: 1052px) {
        h1 {
          font-size: 250%;
        }
      }

    }
    &--detail {
      margin-left: -86px;
      width: calc(100% + 86px);

      @media screen and (min-width: 601px) {
        margin-left: 0;
        width: 100%;
      }
    }
  }
}

.card-event--body {
  .list-column {

    @media screen and (min-width: 601px) {
      column-count: 2;
      column-gap: var(--offset-xs);
    }

    @media screen and (min-width: 961px) {
      column-count: 3;
    }
    @media screen and (min-width: 1281px) {
      column-gap: var(--offset-s);
    }
  }
}

.-popup-video- {
  z-index: 1000;
  position: absolute;
  width: 50%;
  min-width: 800px;
  border-radius: 10px;
  padding-bottom: 28.15%;
  right: 50%;
  transform: translateX(50%);

  @media screen and (max-width: 1590px) {
    padding-bottom: 450px;

  }


  @media screen and (max-width: 900px) {
      min-width: 95%;
      padding-bottom: 53.4%;
  }

  .-video-youtube- {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
  }

  .info-popup__toggle {
    top: -30px;
    right: -30px;

    @media screen and (max-width: 900px) {
        top: -40px;
        right: 10px;
    }
  }
}

.short-card {
  color: var(--color-text);

  //button,
  a[data-e-link] {
    color: currentColor;

    @media screen {
      &:hover {
        color: var(--color-primary);
      }
    }
  }

  &--line {
    margin-top: var(--offset-x);
    padding-left: var(--offset-xx);

    .btn--primary {
      &[disabled] {
        pointer-events: none;
        opacity: .3;
      }
    }

    &:first-of-type {
      margin-top: 0;
    }

    &:first-of-type,
    &:last-of-type {
      padding-left: 0;
    }
  }

  [data-e-title] {
    font-size: var(--offset-sx);
    font-weight: 700;
    color: var(--color-title);
    line-height: 1.35;
  }

  .card--d {
    margin: 0 4px;
  }

}

