.card--bellow {
  border-radius: var(--radius-l);
  height: 200px;
  position: relative;
  overflow: hidden;

  img {
    border-radius: var(--radius-m);
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .card {

    &--fave {
      position: absolute;
      top: 0;
      right: 0;
      display: none;
    }

    &--link {
      color: #fff;

      &:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-height: 70%;
        z-index: 1;
        background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 56.25%);
        transition: max-height .5s;
      }
    }

    &--content {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: var(--offset-x) var(--offset-xs);
      z-index: 2;
      border-radius: var(--radius-m);
    }

    &--title {
      text-transform: uppercase;
      font-size: 112.5%;
      font-weight: 900;
      display: block;
      margin-bottom: 6px;
    }

    &--d {
      font-weight: 900;
      padding: 0 6px;
    }

  }

  @media screen {
    img {
      transform-origin: center;
      transition: transform 10s;
    }

    &:hover {
      .card--link:before {
        max-height: 90%;
      }

      img {
        transform: scale(1.1);
      }
    }
  }

  @media screen and (min-width: 601px) {
    height: 280px;

    .card {
      &--content {
        padding: var(--offset-xs) var(--offset-s);
      }
      &--title {
        font-size: 187.5%;
      }
    }
  }
}
