.cards--list {
  width: 300px;
  margin: 0 auto;
  table-layout: fixed;

  @media screen and (min-width: 375px) {
    width: 100%;
    margin: 0;
  }
}

.card--long {
  border-radius: var(--radius-l);
  position: relative;
  border: 1px solid var(--color-shadow);
  display: flex;
  padding: var(--offset-xx);

  @media screen and (min-width: 701px) {
    height: 180px;
    padding: 0;
  }

  .card {
    &--ribbon {
      display: flex;
      position: absolute;
      right: -9px;
      width: 44px;
      height: 36px;
      bottom: 9px;

      &[hidden] {
        display: none;
      }

      svg {
        fill: currentColor;
        width: 100%;
        height: 100%;
      }

      b {
        position: absolute;
        left: 0;
        top: 18px;
        font-size: 87%;
        width: 100%;

        text-align: center;
        color: #fff;
      }

      @media screen and (min-width: 375px) {
        width: 50px;
        height: 40px;

        b {
          top: 20px;
          font-size: 100%;
        }
      }

      @media screen and (min-width: 701px) {
        width: 69px;
        height: 54px;
        top: 64px;
        bottom: initial;

        b {
          top: 28px;
          font-size: 124%;
        }
      }
    }

    &--datetime {
      //&-time,
      //&-month,
      &-day {
        font-weight: 700;
      }

      &-week {
        display: none;
      }

      @media screen and (min-width: 701px) {
        font-size: 100%;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 156px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        color: var(--color-text-light);
        line-height: 1.75;

        .card--datetime {
          &-day {
            word-spacing: 99999px;
            text-align: center;
          }

          &-week {
            margin-top: var(--offset-x);
            display: initial;
          }
        }
      }
    }

    &--thumb {
      img {
        max-width: 100px;
        border-radius: var(--radius-l);
      }
      @media screen and (min-width: 701px) {
        margin-left: 156px;
        img {
          border-radius: 0;
          max-height: 178px;
          max-width: 100%;
          height: 100%;
          width: auto;
        }
      }
    }

    &--content {
      flex-grow: 1;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      max-width: calc(100% - 100px);
      //padding: var(--offset-xx) 0 var(--offset-xx) var(--offset-x);
      padding: 8px 28px 8px 8px;

      @media screen and (min-width: 701px) {
        padding: var(--offset-x) var(--offset-x) var(--offset-x) var(--offset-s);
        max-width: calc(100% - 126px - 156px);
        font-size: 100%;
        height: 100%;
      }
      @media screen and (min-width: 1281px) {
        padding-left: var(--offset-sm);
        max-width: calc(100% - 126px - 156px - 150px);
      }
    }

    &--title {
      font-size: 131.25%;
      line-height: 1.2;
      font-weight: 700;
      color: var(--color-title);
      overflow: hidden;
      display: block;

      strong {
        height: 38px;
        display: block;
      }

      @media screen and (min-width: 701px) {
        font-size: 150%;

        line-height: 1.35;
        padding-right: var(--offset-m);
		
		height: 65px;
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;    
		text-overflow: ellipsis;
		
        strong {
          height: 74px;
        }
      }
    }

    &--location {
      margin-top: auto;
      margin-bottom: 0;
    }

    &--hash {
      color: var(--color-text-light);
      font-size: 87%;
      padding: 0 40px 0 0;

      a {
        display: inline-block;
        padding: 0.75em 0;
      }
      @media screen and (min-width: 701px) {
        a {
          padding-top: 0;
        }
      }
    }

    &--price {
      margin-top: 4px;

      @media screen and (min-width: 601px) {
        margin-top: 8px;
      }
    }

    &--location,
    &--price {
      color: var(--color-text-light);

      @media screen and (min-width: 701px) {
        font-weight: 600;
      }
    }

    &--fave {
      position: absolute;
      top: 0;
      right: 0;

      @media screen and (min-width: 375px) {
        display: flex;
      }

      @media screen and (min-width: 701px) {
        right: var(--offset-x);
        top: 9px;
      }

      @media screen and (max-width: 415px) {
        svg {
          width: 20px;
          height: 20px;
        }
      }

      // @media screen and (min-width: 1281px) {
      //   transform: scale(.001);
      //   opacity: 0;
      // }

      svg {
        fill: currentColor;
        margin-top: 4px;
      }

      &.-in- {
        color: var(--color-primary);
      }

      &.-out- {
        color: var(--color-title);
      }
    }

    &--hint {
      padding: 24px var(--offset-x);
      display: none;
      flex-direction: column;
      justify-content: flex-end;
      width: 266px;
      flex-shrink: 0;

      .btn--primary {
        transform: rotateX(90deg);
        transform-origin: bottom;
        opacity: 0;
      }

      &-discount {
        font-weight: 600;
        color: var(--color-primary);
        display: block;
        transform: translateY(48px);
        transition: opacity 0.2s, transform 0.2s;
        text-align: right;
      }

      @media screen and (min-width: 1281px) {
        display: flex;
      }
    }
  }

  // @media screen {
  //   &:hover {
  //     .card--hint {
  //       .card--hint-discount {
  //         //opacity: 0;
  //         transform: translate(0, -10px);
  //       }
  //       .btn--primary {
  //         opacity: 1;
  //         transform: none;
  //       }
  //     }
  //   }
  // }
}

.card--fave {
  border-radius: 100%;

  &.-is-filled- {
    color: var(--color-primary);
  }

  @media screen {
    &:hover {
      &.-is-filled-,
      &.-in- {
        opacity: 0.8;
      }

      &.-out- {
        color: var(--color-primary);
      }
    }
  }
}

.card--location p,
.card--hash p {
  display: inline-block;
}

.card--hash p {
  margin-bottom: 4px;
}

.card--archive *{
	opacity: 0.65;
}
.card--archive img{
	filter: grayscale(100%);
}

.card--archive .event-archived, div:not(.card--archive).card--long .event-sold {
	opacity: 1;
    position: absolute;
    top: 40%;
    left: calc(50% - 100px);
    transform: rotateZ(-15deg);
    color: red;
    font-size: 20px;
    font-weight: 700;
    border: solid 2px red;
    background: white;
    padding: 5px;
}