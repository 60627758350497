.search--form {
  display: flex;
  height: 62px;
  align-items: center;
  margin: var(--offset-xx) 0 var(--offset-x) calc(-1 * 100% / 18 );
  width: calc(100% + 100% / 9);
  position: sticky;
  padding: 0 5%;
  top: var(--header-height);
  z-index: 51;
  background-color: var(--color-bg);

  input {
    height: 62px;
    padding: 0 var(--offset-ss);
    font-size: 100%;
    font-weight: 700;
    flex-shrink: 0;
    width: 100%;
    margin-left: calc(-1 * var(--offset-ss));
    margin-right: calc(-2 * var(--offset-ss));
    background-color: transparent;
    border: 1px solid transparent;
    border-bottom-color: var(--color-title);

    @media screen and (min-width: 601px) {
      font-size: 131.25%;
    }
    @media screen and (min-width: 801px) {
      font-size: 150%;
      margin-left: calc(-1 * var(--offset-sm));
      margin-right: calc(-2 * var(--offset-sm));
      padding: 0 var(--offset-sm);
    }
    @media screen and (min-width: 1052px) {
      font-size: 175%;
    }
  }

  //label,
  //button {
  //  flex-shrink: 0;
  //  width: var(--offset-s);
  //
  //  @media screen and (min-width: 601px) {
  //    width: var(--offset-sm);
  //  }
  //}

  button {
    flex-shrink: 0;
  }

  &-close {
    color: var(--color-primary);
    z-index: 1;
    left: -9px;
  }

  &-clear {
    right: -9px;
  }

  label {
    color: var(--color-primary);

    span {
      max-width: 0;
      max-height: 0;
      display: block;
      opacity: 0;
      visibility: hidden;
    }

    svg {
      fill: currentColor;
    }
  }

  @media screen and (min-width: 601px) {
    margin-top: var(--offset-x);

    label svg {
      width: 27px;
      height: 27px;
    }
  }

  &-img-mic {
    color:var(--color-text-light);
  }

  .mic-recording {
    color:var(--color-primary);
    animation: fading 1.5s infinite;
  }
}

@keyframes fading {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: .75;
    transform: scale(.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.section--search {
  max-height: 0;
  overflow: hidden;
  opacity: 1;
  padding: 0;

  .section--archive-aside {
    top: calc(var(--offset-xs) + var(--header-height) + 62px);
    flex-direction: column;
    box-shadow: 0 1px 4px var(--color-shadow);
    background-color: #fff;
    color: var(--color-text-light);
    border: 1px solid var(--color-shadow);
    border-radius: var(--radius-l);
    padding: var(--offset-xx) 0;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    min-height: initial;
    //will-change: transform;
    //transition: transform .2s;

    button {
      width: 100%;
      min-height: 52px;
      display: flex;
      justify-content: space-between;
      padding: var(--offset-xx) var(--offset-x);
      align-items: center;
      text-align: left;

      span {
        width: 24px;
        height: 24px;
        border-radius: var(--radius-s);
        display: inline-flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: var(--color-title);
        margin-left: 12px;
        font-size: 87%;
      }
    }

    @media screen and (max-width: 1051px) {
      margin: 0;
      padding: 0;
    }
    @media screen and (min-width: 1052px) {
      max-height: initial;
      overflow: initial;
      opacity: 1;
    }
  }
  .section-archive--header {
    top: calc(62px + var(--header-height));
    padding-right: 0;
  }
}

[data-search-open="true"] {
  .header.animated {
    animation-name: slideDown;
  }
  .section--search {
    max-height: initial;
    overflow: initial;
    opacity: 1;
    padding: 0 0 var(--offset-sm);
    flex-grow: 1;
  }
  .main {
    max-height: 0;
    overflow: hidden;
    padding: 0;
    opacity: 0;
  }
  .btn--search {
    background-color: rgba(255,255,255,.1);
    border-radius: var(--radius-l);
    color: var(--color-primary);
  }
}
