.section-super-top {
  padding: var(--offset-x) 0;

  @media screen and (min-width: 961px) {
    padding: 0;
  }

  .-slider- {
    border-radius: var(--radius-l);
  }

  .btn--circle-nav {
    position: absolute;
    top: 50%;
    margin-top: -26px;
    z-index: 2;

    @media screen and (max-width: 600px) {
      width: 40px;
      height: 40px;
      margin-top: -20px;
    }
  }

  .-prev- {
    left: 1px;
    @media screen and (min-width: 601px) {
      left: calc(-1 * var(--offset-xx));
    }
  }
  .-next- {
    right: 1px;
    @media screen and (min-width: 601px) {
      right: calc(-1 * var(--offset-xx));
    }
  }

  .card--big {
    margin: 0 var(--offset-xx);
  }

  .-slider- {
    margin-left: calc(-1 * var(--offset-x));
    margin-right: calc(-1 * var(--offset-x));

    .card--big {
      margin: 0 var(--offset-x);

      img {
        width: 100%;
      }
    }
  }

}

.-slider- {
  margin-left: -9px;
  margin-right: -9px;
  position: relative;

  &-thumbs- {
    overflow: initial !important;

    & > div:after {
      content: '';
      display: table;
      height: 0;
      clear: both;
    }
  }

  &[data-type=multiple] {

    .card {
      margin-bottom: var(--offset-x);

      &:last-child {
        margin-bottom: 0;
      }
    }

    .btn--circle-nav {
      position: absolute;
      opacity: 0;
      visibility: hidden;
      top: calc(50% - 21px);
    }
  }

}

.section--slider {
  overflow: hidden;
}

.section-super-top {
  .slider--cls-fix {
    .-slider--thumbs-,
    .-slider--thumbs-[style] {
      aspect-ratio: 1.8;
    }
  }

  @media screen and (min-width: 481px) {
    .slider--cls-fix {
      .-slider--thumbs-,
      .-slider--thumbs-[style] {
        aspect-ratio: 3.7;
      }
    }
  }
}

