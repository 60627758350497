.card--normal {
  border-radius: var(--radius-l);
  border: 1px solid var(--color-shadow);
  display: flex;
  padding: var(--offset-xx);

  .card {

    &--thumb {
      width: 100px;
      flex-shrink: 0;
      position: relative;

      img {
        border-radius: var(--radius-l);
      }
    }

    &--title {
      color: var(--color-title);
      font-size: 131.25%;
      line-height: 1.2;
      max-height: 2.4em;
      overflow: hidden;
    }

    &--content {
      padding: var(--offset-xx) var(--offset-x);
      max-width: calc(100% - 100px);
      font-size: 12px;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      color: var(--color-text-light);
    }

    &--datetime {
      color: var(--color-text);
    }

    &--hash {
      color: var(--color-text-light);
      font-size: 87%;
      padding: 0;

      a {
        padding: .75em 0;
        display: inline-block;
      }
    }

    &--location {
      margin-top: auto;
      margin-bottom: 4px;
    }

    &--data {
      margin-top: auto;
      margin-bottom: 12px;
      display: flex;

      svg {
        fill: currentColor;
        margin-right: 5px;
        flex-shrink: 0;

        @media screen and (min-width: 601px) {
          margin-right: 14px;
        }
      }

      button {
        font-weight: 600;
        color: currentColor;
      }

      svg {

      }

      &-desc {

      }
    }

  }

  @media screen and (min-width: 601px) {
    height: 280px;
    padding: 0;

    .card {
      &--thumb {
        width: 196px;

        img {
          border-radius: var(--radius-l) 0 0 var(--radius-l);
          height: 100%;
          max-height: 100%;
          display: block;
        }
      }
      &--title {
        font-size: 131.25%;
        padding-top: var(--offset-x);
        max-height: 116px;
      }
      &--location {
        margin-bottom: 8px;
      }
      &--content {
        padding: var(--offset-x) var(--offset-s);
        font-size: 16px;
        max-width: calc(100% - 196px);
      }
    }
  }

  @media screen and (min-width: 1440px) {
    .card {
      &--content {
        font-size: 18px;
        padding: var(--offset-sx) var(--offset-s);
      }
      &--title {
        max-height: 106px;
      }
      &--datetime {
        font-weight: 700;
      }
    }
  }

 /* .card {
    &--location,
    &--price,
    &--datetime {
      font-size: 112.5%;
    }

    &--hash {
      padding-top: 9px;
    }

    &--datetime {
      font-weight: 700;
    }

    &--location {
      margin-top: auto;
      margin-bottom: 0;
    }

    &--price {
      margin-top: 12px;
    }

    &--title strong {
      line-height: 1.2;
      font-weight: 700;
      overflow: hidden;
      display: block;
    }

    &--link {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &--datetime,
    &--title {
      color: var(--color-text);
    }

    &--location,
    &--price {
      color: var(--color-text-light);
    }

  }


  @media screen and (min-width: 961px) {
    .card--title strong {
      font-size: 150%;
      max-height: 98px;
    }
  }

  @media screen and (min-width: 300px) {
    .card--title strong {
      max-height: 76px;
      font-size: 131.25%;
      margin: var(--offset-x) 0;
    }
  }

  @media screen and (min-width: 450px) {
    padding: 32px 24px 32px 222px;
    height: 280px;

    img {
      top: 0;
      left: 0;
      border-radius: var(--radius-m) 0 0 var(--radius-m);
      max-height: 278px;
    }
  }

  @media screen and (max-width: 449px) {
    padding: 8px 8px 8px 124px;
    font-size: 12px;

    img {
      border-radius: var(--radius-l);
      width: 100px;
      height: auto;
      top: 8px;
      left: 8px;
    }
  }*/

}
