.footer {
  --color-logo: #011031;

  &--inactive {
    display: none;
  }

  .copy {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: var(--color-text-light);

    & > div {
      margin-left: 0;
      margin-right: auto;
    }

    svg {
      margin-left: 8px;
    }
  }

  .hr {
    margin: var(--offset-m) 0 var(--offset-s);
    height: 1px;
    border: 0;
    background-color: var(--color-shadow);
  }

  .section--row {
    margin-top: 46px;

    @media screen and (max-width: 900px) {
      flex-direction:column;
      row-gap: 46px;
    }

    @media screen and (max-width: 650px) {
      row-gap: 46px;
    }

    &.-underline- {
      border-bottom: 1px solid #9CAEC9;
      padding-bottom: 46px;
    }
  }

  .section--copyright {
    padding: 46px 0;
  }

  @media screen and (min-width: 1052px) {
    & > .container {
      display: block;
    }
    .section--copyright {
      padding: 46px 0;
    }
  }
}

.widget {
  .dropdown {
	  &--links {
	    .dropdown {
          &--popup {
			a {
			  padding: var(--offset-xx);
			  font-size: 20px;
            }
          }
        }
      }
    }

	.list-column-flex li {
		padding-right: 0;
	}

  &--social {
    flex-direction: column;
    width: 19%;

    @media screen and (max-width: 900px) {
      width: 290px;
    }

    .logo {
      margin-bottom: var(--offset-xl);
      fill: var(--color-title);
      height: 44px;
    }
  }

  &-other {
    display: flex;
    justify-content: space-between;
    width:74%;
    margin-left: 6.5%;

    @media screen and (max-width: 1199px) {
      margin-left: 13%;
      width: 68%;
    }

    @media screen and (max-width: 900px) {
      width: 100%;
      margin-left: 0;
    }

    @media screen and (max-width: 650px) {
      flex-direction: column;
      row-gap: 46px;
    }
  }

  &--category {
    overflow: hidden;

    &:not(:first-child) {
      @media screen and (min-width: 901px) {
        margin-left: 59px;
      }
    }

    &:not(:nth-last-child(-n+2)) {
      @media screen and (min-width: 901px) {
        padding-right: 59px;
        border-right: 1px solid #9CAEC9;
      }
    }

    @media screen and (max-width: 900px) {
      display: none;
    }

    &.mob {
      display: block;

      @media screen and (min-width: 901px) {
        display: none;
      }
    }
  }

  &--schedule {
    display: flex;
    margin-top: 20px;
    gap: 12px 26px;

    @media screen and (max-width: 1199px) {
      flex-direction: column;
    }

    @media screen and (max-width: 650px) {
      margin-top: 0;
    }
    .time-schedule {
      font-size: 16px;
      text-transform: uppercase;
      padding-top: .8em;
      padding-bottom: 1em;
      color: var(--color-text-light);

	    @media screen and (max-width: 650px) {
	      font-size: 18px;
		    line-height: 20px;
		    padding-top: 0;
		    padding-bottom: 12px;
	    }
    }
  }

  &.articles {
    width: 43%;
    margin-right: 0;
    margin-left: auto;
    margin-top: 20px;

    @media screen and (max-width: 1199px) {
      width: 63%;
    }

    @media screen and (max-width: 900px) {
      width: 65%;
    }

    @media screen and (max-width: 650px) {
      width: 100%;
      margin-top: 0;
    }
  }
}

.schedule {
	display: flex;
	flex-direction: column;

	@media screen and (max-width: 650px) {
	  flex-direction: column-reverse;
	}
}

.list-partners, .list-fanshops {
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	margin-left: -9px; /*два*/
	padding: 0;

	.item-partner, .item-fanshop {
	  position: relative;
	  border-left: 1px solid #9CAEC9;
	  margin-top: 15px;

	  .link-partner {
		padding: 0.5em;
		color: #486084;
		font-size: 18px;
		font-weight: 400;

      &.no-link {
        pointer-events: none;
        cursor: default;
      }
	  }
	}
}

.link--social {
  color: var(--color-title);
  vertical-align: top;

  @media screen {
    &:hover {
      color: var(--color-primary);
    }
  }
}

.list-column__item {
  cursor: pointer;
}

.widget__info-popup {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100;
  overflow-y: auto;

  &--active {
    display: block;
  }
}

.widget__info-popup .content-info ul {
  list-style-type: circle;
  padding-left: 20px;
}

.widget__info-popup .content-info li {
    width: 100%;
    display: block;
    padding: 0;
    text-align: left;
    font-weight: 400;
}

.articles-right {
  margin-right: 0;
}

.articles-between {
  margin-right: auto;
  margin-left: auto;
}

.info-popup__toggle {
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: #273d71;
  border-radius: 50%;

  position: fixed;
  right: 40px;
  top: 15px;
  opacity: 0.5;
  z-index: 1000;
}

.info-popup__toggle:hover {
  opacity: 1;
}
.info-popup__toggle::before, .info-popup__toggle::after {
  position: absolute;
  left: 14px;
  content: ' ';
  height: 15px;
  width: 2px;
  background-color: #fff;
}
.info-popup__toggle::before {
  transform: rotate(45deg);
}
.info-popup__toggle::after {
  transform: rotate(-45deg);
}

.content-info {
  position: relative;
  width: 80%;
  margin: 0 auto;
  background-color: #fff;
  padding: 12px;
  text-align: left;
}

.content-info p {
  font-weight: 400;
  margin-bottom: 0;
  max-width: 100%;
}

.content-info h4 {
  margin: 12px 0;
  color: var(--color-text-light);
}

.content-info h5 {
  margin: 8px 0;
  color: var(--color-text-light);
}

.section-archive--content {
  max-width: 100%;
}

// Cookies popups

.cookies-popup-wrap {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1000;
  flex-wrap: nowrap;
  height: auto;
  width: 100%;
  max-width: 552px;
  font-family: Helvetica, Calibri, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5em;
  color: #011031;
}

.cookies-popup-wrap.displayed {
  opacity: 1;
  display: flex;
  animation: fadein 2s;
}

.cookies-popup {
  display: block;
  margin: 16px;
  max-width: 520px;
  box-shadow: 0px 4px 16px 0 rgba(0, 0, 0, 0.16);
  padding: 25px;
  background-color: #fff;
  color: #1d2e38;
  border-radius: 5px;
}

.popup-hide {
  display: none;
}

.cookies-popup-title {
  font-size: 1.8em;
  font-weight: 400;
  padding: 16px 0 4px 0;
}

.cookies-popup-text {
  margin-top: 16px;
}

.cookies-popup-link {
  margin-bottom: 16px;
  font-weight: 700;
  border: 0;
  color: inherit;
  text-decoration: none;
  -webkit-transition: color .3s;
  transition: color .3s;
  cursor: pointer;
}

.cookies-popup-settings-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0;
  user-select: none;
  gap: 16px;
  @media screen and (min-width: 480px) {
    flex-direction: row;
  }
}

.cookies-popup-settings-checkboxes {
  width: 100%;
  @media screen and (min-width: 480px) {
    width: 35%;
  }
}

.cookies-popup-settings-description {
  width: 100%;
  @media screen and (min-width: 480px) {
    width: 65%;
  }
}

.cookies-popup-settings .cookies-popup-button-wrap {
  margin-top: 16px;
}

.cookies-popup-button-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: start;
  margin-top: 32px;
}

.cookies-popup-button {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px 12px;
  font-size: 15px;
  line-height: 15px;
  font-weight: 700;
  color: #fff;
  background-color: #011031;
  cursor: pointer;
  -webkit-transition: all .25s ease-in-out;
  -moz-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
}
.cookies-popup-button:hover {
  opacity: 0.7;
}
.cookies-popup-button-reject {
  color: #fff;
  background-color: #EE4256;
  font-family: inherit;
  font-size: 15px;
  font-weight: bolder;
  transition: filter .15s;
}

.cookies-popup-button-accept {
  color: #fff;
  background-color: #15cb84;
  font-family: inherit;
  font-size: 15px;
  font-weight: bolder;
  -webkit-transition: color .1s ease-out;
  transition: color .1s ease-out;
  transition-duration: .1s;
  transition-timing-function: ease-out;
  transition-delay: 0s;
  transition-property: color;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.cc-categories {
  font-size: 1.1em;
  padding-right: 5px;
}

.cc-categories div[role="checkbox"] {
  width: 100%;
  margin-top: 4px;
  margin-left: 8px;
  display: inline-block;
  position: relative;
  padding-left: 1.4em;
  cursor: pointer;
  color: #1d2e38;
}

.cookies-popup-settings-checkboxes [role="checkbox"][aria-checked="mixed"]::before,
.cookies-popup-settings-checkboxes [role="checkbox"][aria-checked="true"]::before {
  border-color: #011031;
  background: #011031;
}

.cookies-popup-settings-checkboxes [role="checkbox"]::before {
  width: 14px;
  height: 14px;
  border: 2px solid #011031;
  position: absolute;
  top: 50%;
  left: 7px;
  transform: translate(-50%, -50%);
  content: '';
}


.cc-categories span[role=link] {
  border-bottom: unset;
  outline: none;
  border-bottom: 1px solid #011031;
  padding-bottom: 0.1em;
  word-wrap: break-word;
  border-bottom: unset;
}

.cookies-popup-settings-checkboxes [role="checkbox"][aria-checked="mixed"]:active::after,
.cookies-popup-settings-checkboxes [role="checkbox"][aria-checked="true"]::after {
  display: block;
  width: 0.25em;
  height: 0.4em;
  border: solid #fff;
  border-width: 0 0.2em 0.2em 0;
  transform: translateY(-65%) translateX(-50%) rotate(45deg);
}

.cookies-popup-settings-checkboxes [role="checkbox"]::after {
  position: absolute;
  top: 50%;
  left: 7px;
  transform: translate(-50%, -50%);
  content: '';
}

.widget-wrapper.widget-right .widget-fab {
  left: 25px !important;
  bottom: 25px !important;
}



