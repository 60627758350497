.card--line {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: var(--offset-x);

  @media screen and (min-width: 961px) {
    border-radius: var(--radius-l);
    background-color: #fff;
    border: 1px solid var(--color-shadow);
    align-items: center;
    flex-direction: row;
    margin-top: var(--offset-s);
  }

  &-head {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    border-bottom: 1px solid var(--color-shadow);
    margin-bottom: var(--offset-xx);
    padding-bottom: 6px;

    @media screen and (min-width: 961px) {
      padding: 36px 18px 36px var(--offset-sx);
      width: 280px;
      flex-shrink: 0;
      flex-direction: column;
      align-items: center;
      margin-bottom: 0;
      border-bottom: 0;
    }

    @media screen and (min-width: 1052px) {
      width: 380px;
      padding-left: var(--offset-s);
    }
    @media screen and (min-width: 1281px) {
      padding-left: var(--offset-ml);
    }

  }

  .card {
    &--title {
      font-weight: 700;
      color: var(--color-title);
      text-transform: uppercase;
      width: 50%;
      flex-shrink: 0;

      @media screen and (min-width: 961px) {
        font-size: 150%;
        width: 100%;
      }
    }

    &--location {
      color: var(--color-text-light);
      font-size: 75%;
      width: 50%;
      flex-shrink: 0;
      padding-left: var(--offset-xx);
      text-align: right;

      br {
        display: none;
      }

      @media screen and (min-width: 961px) {
        line-height: 1.5;
        margin-top: var(--offset-xs);
        font-size: 100%;
        padding-left: 0;
        width: 100%;
        text-align: initial;

        br {
          display: initial;
        }
      }
    }
  }

  &-list {

    ul {

      li {
        padding: var(--offset-xx) 0;
        display: inline-block;
        width: 100%;
        vertical-align: top;
      }
      @media screen and (min-width: 961px) {
        column-gap: var(--offset-s);
        column-count: 2;
      }
      @media screen and (min-width: 1152px) {
        column-gap: var(--offset-s);
        column-count: 3;
      }
    }

    @media screen and (max-width: 1151px) and (min-width: 961px) {

    }
    @media screen and (max-width: 960px) and (min-width: 581px) {

    }
    @media screen and (max-width: 580px) {

    }



  }

  &-times {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    margin-left: -9px;

    @media screen and (min-width: 961px) {
      padding: 36px 40px 14px 0;
      margin-left: 0;
    }
  }

  &-time {
    margin-left: var(--offset-xx);
    width: calc(50% - var(--offset-xx));
    margin-bottom: var(--offset-sx);

    @media screen and (min-width: 601px) {
      width: calc((100% / 3) - var(--offset-xx));
    }

    @media screen and (min-width: 801px) {
      width: calc((100% / 3) - var(--offset-xx));
    }

    @media screen and (min-width: 961px) {
      width: calc((100% / 3) - var(--offset-x));
      margin-left: var(--offset-x);
      // margin-bottom: var(--offset-sx);
    }
    @media screen and (min-width: 1281px) {
      width: calc(25% - var(--offset-x));
    }
  }

  &--card {
    display: flex;
    flex-direction: column;

    & > small {
      // display: none;

      // @media screen and (min-width: 961px) {
        margin-bottom: 6px;
        color: var(--color-text-light);
        display: block;
      // }
    }

    small {
      font-size: 75%;
      line-height: 114%;
      text-align: center;

      @media screen and (min-width: 961px) {
        font-size: 87.5%;
      }
    }
  }

}

