.section--content {

  .list-column {
    li:last-of-type {
      margin-bottom: 0;
    }
    @media screen and (min-width: 481px) {
      column-count: 2;
      column-gap: var(--offset-x);
      font-size: 112.5%;
    }
    @media screen and (min-width: 801px) {
      column-count: 3;
    }
    @media screen and (min-width: 1052px) {
      column-count: 4;
    }
  }

  .list-column {
    &--footer {
      padding-top: var(--offset-xx);

      .btn--link {
        font-size: 87.5%;

        @media screen and (min-width: 601px) {
          font-size: 100%;
        }

        @media screen and (min-width: 1052px) {
          font-size: 131.25%;
        }
      }

      @media screen and (min-width: 601px) {
        padding-top: var(--offset-x);
      }
    }
  }

}
