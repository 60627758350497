
%collapsedContent {
  overflow: hidden;

  &.-opened- {
    max-height: initial;
    overflow: initial;
  }
}

