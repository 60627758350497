.nav {
  padding: var(--offset-x) 0;
  font-size: 112.5%;

  .container {
    display: flex;
    justify-content: space-between;
  }

  &--link {
    display: flex;
    flex-grow: 1;
    align-items: center;
    font-weight: 900;
    height: 55px;
    color: var(--color-title);
    text-transform: uppercase;
    position: relative;
    white-space: nowrap;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: -40px;
      margin-top: -8px;
      height: 16px;
      width: 1px;
      background-color: var(--color-shadow);
    }

    svg {
      fill: currentColor;
      margin-left: 12px;
    }

    &:not(:first-child) {
      margin-left: 80px;
    }

    &:not(:first-child):not(:last-child) {
      justify-content: center;
    }

    &:first-child {
      justify-content: flex-start;

      &:before {
        display: none;
      }
    }

    &:last-child {
      justify-content: flex-end;
    }

    @media screen {
      &:hover {
        color: var(--color-primary);
      }
    }

  }

  @media screen and (min-width: 961px) and (max-width: 1280px) {
    a:nth-of-type(3) ~ a {
      display: none;
    }
  }
  @media screen and (min-width: 1281px) and (max-width: 1440px) {
    a:nth-of-type(4) ~ a {
      display: none;
    }
  }
  @media screen and (min-width: 1441px) and (max-width: 1500px) {
    a:nth-of-type(5) ~ a {
      display: none;
    }
  }
  @media screen and (min-width: 1501px) {
    a:nth-of-type(6) ~ a {
      display: none;
    }
  }
}

.is-home {
  @media (min-width: 961px) {
    .nav[hidden] {
      display: block;
    }
  }
}

.nav--drop {
  position: fixed;
  padding-top: var(--header-height);
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  bottom: 0;
  z-index: 54;
  overflow-y: scroll;

  &-foot {
    .-trigger- {
      width: 100%;
      color: var(--color-text-light);
      text-align: left;

      .-arr- {
        position: absolute;
        top: var(--offset-xx);
        right: 0;
      }

      span {
        display: flex;
        align-items: center;
        font-weight: 600;
        color: var(--color-text);
        height: var(--offset-xs);
        margin-top: 4px;

        svg {
          margin-right: var(--offset-xx);
        }
      }
    }
    &[data-parent-collapse] {
      span {
        display: flex;
      }
    }

    [data-collapse-view] {
      margin-top: calc(-1 * var(--offset-xs));
      margin-left: var(--offset-xs);
      background-color: #fff;
      position: relative;
      z-index: 6;
      line-height: var(--offset-xs);
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      a {
        color: var(--color-text);
        font-weight: 600;
        line-height: var(--offset-xs);

        @media screen {
          &:hover {
            color: var(--color-primary);
          }
        }
      }
    }
    &[data-view="hide"] {
      [data-collapse-view] {
        display: none;
      }
    }
    &[data-view="show"] {
      [data-collapse-view] {
        display: flex;
      }
      .-trigger- .-arr- {
        transform: rotate(180deg);
      }
    }
  }

  .-loading- {
    width: 100%;
    height: initial;
    color: var(--color-primary);
    @media screen and (min-width: 801px) {
      max-width: 300px;
    }
  }

  .container {
    display: flex;
    min-height: 100%;
    position: relative;

    &:before {
      z-index: -1;
      background: #fff;
      width: 100%;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      flex-shrink: 0;
    }

    @media screen and (min-width: 801px) {
      &:before {
        content: '';
        left: calc(300px - 95%);
      }
    }

    @media screen and (min-width: 1801px) {
      &:before {
        left: calc(360px - 100%);
      }
    }

  }

  .-hr- {
    margin: var(--offset-xs) 0;
    height: 1px;
    background-color: var(--color-brdr);
  }

  .-sticky- {
    margin-top: auto;
    margin-bottom: 0;

    @media screen and (min-width: 801px) {
      position: sticky;
      padding-top: 1px;
      bottom: 0;
      z-index: 2;
      background-color: #fff;
    }
  }

  .links {
    padding: var(--offset-x) 0;
  }

  .container > ul {
    min-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: var(--offset-x);
    //background-color: var(--color-bg);

    @media screen and (min-width: 801px) {
      max-width: 300px;
      padding-right: var(--offset-x);
    }

    li > a {
      display: inline-block;
      padding: var(--offset-xx) 0;
      font-weight: 600;
      color: var(--color-text-light);
      line-height: 1.3;

      @media screen {
        &:hover {
          color: var(--color-primary);
        }
      }
    }

    .-towns--list- li {
      & > a {
        font-weight: 400;
      }
      & > span {
        display: inline-block;
        padding: var(--offset-xx) 0;
        font-weight: 600;
        color: var(--color-text-light);
        line-height: 1.3;
      }
      &.-current- {
        & > a,
        & > span {
          color: var(--color-primary);
          text-decoration: underline;
        }
      }
    }
    //li {
    //  position: static;
    //  z-index: 1;
    //}
  }

  &[data-view="hidden"] {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
  }

  .dropdown {
    position: static;

    @media screen and (max-width: 800px) {
      flex-direction: column;
    }

    &--trigger-item {
      padding: 0;
      font-size: 100%;
      font-weight: 600;
      height: 36px;
    }

    &--popup {
      border: none;
      box-shadow: none;
      border-radius: 0;
      transform: none;

      @media screen and (max-width: 414px) {
        font-size: 90%;
      }

      @media screen and (max-width: 800px) {
        position: static;
        width: 100%;
        flex-shrink: 0;
      }
      @media screen and (min-width: 801px) {
        top: var(--header-height);
        max-height: calc(90vh - var(--header-height));
        box-shadow: 4px 4px 4px -1px var(--color-shadow);
        border-radius: 0 0 var(--radius-l) 0;
        background-color: var(--color-bg);
        overflow-y: auto;
        flex-direction: column;
        justify-content: flex-start;
        padding: var(--offset-xs) 0;
        min-width: initial;
        position: fixed;

        &.-fh- {
          max-height: calc(100vh - var(--header-height));
          bottom: 0;
        }
      }
      @media screen and (min-width: 801px) {
        right: 0;
        left: calc(5% + 300px);
        width: calc(95% - 300px);
        padding: var(--offset-sm) var(--offset-s);
      }
      @media screen and (min-width: 1281px) {
        padding: var(--offset-m) var(--offset-sm);
      }
      @media screen and (min-width: 1801px) {
        --left-d: calc((100vw - 1800px) / 2);
        left: calc(var(--left-d) + 360px);
        width: calc(1440px - var(--left-d));
      }
    }


  }

  [data-langbox] {
    display: flex;
    align-items: center;
    font-size: 87.5%;

    span {
      margin-left: var(--offset-x);
      border-bottom: 2px solid var(--color-primary);
      font-weight: 600;
    }

    a {
      margin-left: var(--offset-xx);
      color: var(--color-text-light);

      @media screen {
        &:hover {
          color: var(--color-primary);
        }
      }
    }
  }

  @media screen and (min-width: 801px) {
    background-color: rgba(248, 249, 251, .5);
  }
}

.btn--nav {
  svg {
    &:nth-child(2) {
      display: none;
    }
  }
}

body[data-open-nav="show"] {
  overflow: hidden;

  .btn--nav {
    svg {
      &:first-child {
        display: none;
      }
      &:nth-child(2) {
        display: initial;
      }
    }
  }

  .header {
    position: sticky;
    top: 0;
  }
}

.profile-link {
  svg {
    margin-right: 9px;
    fill: currentColor;
  }

  @media screen {
    &:hover {
      color: var(--color-primary);
    }
  }
}

.-nav {
  &-category- {
    @media screen and (min-width: 961px) {
      column-count: 2;
      column-gap: var(--offset-xs);
    }
    @media screen and (min-width: 1281px) {
      column-count: 3;
    }
  }
  &-towns- {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;

    @media screen and (min-width: 415px) {
      flex-direction: row;
    }
  }
  &-picker- {
    @media screen and (max-width: 800px) {
      width: 100%;
    }
  }
}

.-nav-towns-,
.-nav-category- {
  @media screen and (max-width: 800px) {
    border-left: 1px solid var(--color-border);
    padding-left: var(--offset-xs);
  }
}

.-towns- {

  &-list- {
    flex-grow: 1;
    border-left: 1px solid var(--color-border);
    padding-left: var(--offset-x);
    width: 100%;

    @media screen and (min-width: 415px) {
      border: none;
      padding-left: 0;
      width: calc(100% - 200px);
    }
    @media screen and (min-width: 961px) {
      column-count: 2;
      column-gap: var(--offset-xs);
    }
    @media screen and (min-width: 1281px) {
      column-count: 3;
    }
    @media screen and (min-width: 1441px) {
      column-gap: var(--offset-sm);
    }
  }

  &-nav- {
    width: 100%;
    flex-shrink: 0;


    @media screen and (min-width: 415px) {
      width: 200px;
      padding-right: var(--offset-m);
    }

    button {
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & + button {
        margin-top: var(--offset-xx);
      }

      svg {
        fill: var(--color-primary);
        display: none;

        @media screen and (min-width: 415px) {
          transform-origin: center;
          transform: rotate(-90deg);
        }
      }

      &.-current- {
        font-weight: 600;
        color: var(--color-primary);
        width: 100%;

        svg {
          display: initial;
        }
      }


      @media screen {
        &:hover {
          color: var(--color-primary);
        }
      }
    }
  }
}
