.header {
  display: flex;
  align-items: stretch;
  justify-content: center;
  background-color: var(--color-title);
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 55;
  flex-shrink: 0;
  height: var(--header-height);

  .logo {
    text-align: left;
    display: block;
    height: var(--header-height);
    padding: var(--offset-x) 0;
    margin-left: 36px;

    svg {
      fill: #fff;
      height: 26px;
      width: auto;
    }

    @media screen and (min-width: 375px) {
      text-align: center;
      margin-left: 0;
      padding: 16px 0;

      svg {
        height: 30px;
      }
    }
    @media screen and (min-width: 801px) {
      svg {
        height: 44px;
      }
    }
  }

  &--manage {
    margin-top: calc(-1 * var(--header-height));
    height: var(--header-height);
    margin-right: -12px;
    width: calc(100% + 12px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0;

    &[hidden] {
      display: flex;
    }

    button {
      color: #fff;
      font-weight: 600;
      height: 42px;

      @media screen {
        &:hover {
          color: var(--color-primary);
        }
      }
    }

    .btn--nav {
      margin-right: auto;
      margin-left: -18px;
      padding-left: 18px;

      span {
        display: none;

        @media screen and (min-width: 801px) {
          display: inline;
        }
      }

    }

    .btn--rect {
      width: 42px;
      svg {
        width: 18px;
        height: 18px;
      }
      span {
        display: none;
      }
    }

    .btn--logout {
      display: inline-flex;
      align-items: center;
      font-size: 100%;
      color: #fff;
      font-weight: 600;
      height: 26px;
      border-bottom: 1px solid rgba(var(--color-shadow-rbg), .5);
      margin-left: var(--offset-xx);

      @media screen and (min-width: 801px) {
        font-size: 131.25%;
        margin-left: var(--offset-x);
      }
      @media screen {
        &:hover {
          color: var(--color-primary);
          border-bottom-color: var(--color-primary);
        }
      }
    }

    .btn--open-city {
      display: inline-flex;
      width: 42px;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      font-size: 131.25%;

      span {
        display: none;
      }

      svg {
        fill: currentColor;
      }

      @media screen and (min-width: 1052px) {
        border-bottom: 1px solid rgba(var(--color-shadow-rbg), .5);
        margin-left: var(--offset-x);
        height: initial;
        width: auto;

        svg {
          display: none;
        }
        span {
          display: initial;
        }
      }

      @media screen {
        &:hover {
          border-bottom-color: var(--color-primary);
        }
      }
    }

    .dropdown {
      display: none;

      &--trigger-item {
        font-size: 131.25%;
        svg {
          margin-left: 6px;
        }
      }

      &--popup {
        top: 100%;
        min-width: initial;
        width: 70px;

        &:before {
          display: none;
        }

        a {
          display: block;
          font-size: 131.25%;
          font-weight: 600;
          color: var(--color-text-light);

          & + a {
            margin-top: 16px;
          }

          @media screen {
            &:hover {
              color: var(--color-text);
            }
          }
        }
      }

      &[data-view="show"] {
        .dropdown--trigger-item {
          color: var(--color-primary);
        }
      }

      @media screen and (min-width: 801px) {
        display: flex;
      }
    }

    @media screen and (min-width: 801px) {
      button {
        height: 55px;
      }

      width: 100%;
      margin-right: 0;

      .btn--rect {
        width: 55px;
      }
    }
  }

  &.-is-white- {
    background-color: rgba(var(--color-white), 1);

    .logo svg {
      fill: var(--color-title);
    }

    .header--manage button {
      color: var(--color-title);
    }

  }

}

.animated {
  animation-duration: .2s;
  animation-fill-mode: both;
  will-change: transform,opacity;

  &.-un-pin- {
    animation-name: slideUp;
    @media screen and (max-width: 960px) {
      &.-out-top- {
        & ~ .main .section-archive--aside {
          transform: translateY(calc(-1 * var(--header-height)));
        }
      }
    }
  }

  &.-pin- {
    animation-name: slideDown;

    &.-out-top- {
      & ~ .main .card-event--body-aside {
        transform: translateY(var(--header-height));
      }
    }

  }
}
//
//.-profile- {
//  @media screen and (max-width: 960px) {
//    .animated.header {
//      animation-name: slideDown;
//    }
//    .section--profile .section-archive--aside {
//      top: var(--header-height);
//    }
//  }
//}

@keyframes slideUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideAsideUp {
  0% {
    top: var(--offset-x);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes slideAsideDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}


