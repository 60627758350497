.-return- {
  position: relative;

  .h3 {
    margin-bottom: var(--offset-s);
  }

  .form--footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .btn--primary {
      margin-bottom: var(--offset-x);
    }

    a {
      color: var(--color-text-light);

      @media screen {
        &:hover {
          color: var(--color-primary);
        }
      }
    }
  }

  .dropdown {
    width: 100%;

    &.-selected- {
      .dropdown--trigger-item span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 600;
      }
    }

    &--trigger-item {
      height: 52px;
      border: 1px solid var(--color-shadow);
      border-radius: var(--radius-l);
    }

    &--popup {
      margin-top: 5px;
    }

    &--popup-list {
      width: 100%;
      padding: 0 var(--offset-x);
      max-height: 240px;
      overflow-y: auto;
    }

    @media screen and (min-width: 415px) {
      max-width: 375px;

      .dropdown--popup-list {
        padding: 0 var(--offset-xs);
      }
    }
  }

  &[data-loading] {
    .form--loading {
      display: flex;
      align-items: center;
      color: var(--color-primary);
    }
  }

  &[data-complete] {

    .-return--field- {
      max-height: 0;
      overflow: hidden;
      padding: 0;
    }

    .-return--success- {
      display: block;
    }
  }

}

.callback-form {
  &[data-loading] {
    .form--loading {
      display: flex;
      color: var(--color-primary);
    }
  }
  &[data-complete] {
    .-field- {
      max-height: 0;
      overflow: hidden;
      padding: 0;
    }
    .-msg- {
      display: block;
    }
  }
}
