.container-seo {
  button[data-collapsed-trigger] {
    position: relative;

    span {
      display: none;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
    }

    @media screen and (max-width: 600px) {
      font-size: 87.5%;
    }
  }

  &[data-view=hide] {
    .collapsed-content {
      max-height: 166px;

      @media screen and (min-width: 601px) {
        max-height: 56px;
      }
    }
    button[data-collapsed-trigger] {
      span:first-child {
        display: initial;
      }
    }
  }

  &[data-view=show] {
    button[data-collapsed-trigger] {
      span:last-child {
        display: initial;
      }
    }
  }

  .section--title {
	text-align: center;
  }

  .section--content {
    max-width: 880px;
	margin: 0 auto;
  }

}
