
.section--archive {
  position: relative;
  padding-top: var(--offset-s);
  min-height: 100vh;

  &.-empty- {
    min-height: 400px;
  }

  @media screen and (min-width: 961px) {
    padding-top: 0;
  }
  @media screen and (max-width: 600px) {
    .h1 {
      margin-bottom: 9px;
    }
  }

  &-aside {
    width: 100%;
    flex-shrink: 0;
    position: sticky;
    margin-top: var(--offset-x);
    bottom: 0;
    z-index: 50;
    transform: none;

    @media screen and (min-width: 1052px) {
      max-width: 230px;
      //min-height: 400px;
      margin-right: var(--offset-s);
      order: -1;
      top: calc(var(--offset-xs) + var(--header-height));
      bottom: initial;

      &.-is-land- {
        margin-top: var(--offset-s);

        .dropdown .dropdown--popup {
          max-height: calc(100vh - var(--offset-s) - 90px);
          top: -24px;
        }
      }
    }

    @media screen and (min-width: 1281px) {
      margin-right: var(--offset-m);
    }
  }

  &-content {
    flex-grow: 1;
    max-width: 100%;
    width: 100%;
    flex-shrink: 0;

    @media screen and (min-width: 1052px) {
      &.-with-aside- {
        max-width: calc(100% - var(--offset-s) - 230px);
      }
    }
    @media screen and (min-width: 1281px) {
      &.-with-aside- {
        max-width: calc(100% - var(--offset-m) - 230px);
      }
    }
  }

  &-wrap {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @media screen and (min-width: 1052px) {
      flex-direction: row;
    }
  }


  &[data-filter-open] {
    z-index: 100;
  }

  @media screen and (min-width: 1052px) {
    &[data-view="show"] {
      .filter--primary-bg[hidden] {
        display: block;
      }
    }

    &[data-filter-open] {
      z-index: 1;
    }
  }

}

.section--archive {

  .card {
    &[data-filter] {
      opacity: 0;
      max-height: 0;
      overflow: hidden;
      padding: 0;
      margin: 0;
      border: 0;
    }
  }

  span[data-section-point] {
    position: relative;
    top: -73px;
  }

}

.empty-result {
  display: flex;
  align-items: center;
  font-size: 112.5%;
  justify-content: center;
  flex-direction: column;
  padding: var(--offset-s);
  color: var(--color-text);
  min-height: 400px;
  text-align: center;

  [data-to-fave] {
    height: 40px;
    border: 1px solid var(--color-shadow);
    padding: 0;
    min-width: 282px;
    max-width: 300px;
    border-radius: var(--radius-l);
    margin: var(--offset-x) 0 var(--offset-xx);

    @media screen {
      &:hover {
        color: var(--color-primary);
        box-shadow: 0 2px 4px var(--color-shadow);
      }
    }
  }

  svg {
    fill: currentColor;
    margin-bottom: var(--offset-xs);
  }

  b {
    margin-bottom: var(--offset-xx);
  }
}

.section--search .empty-result {
  min-height: 200px;
}

.empty-result[hidden] {
  display: none;
}

.relink {
  display: none;
}
