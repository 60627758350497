////////////////////////////////////////
//		Reset
////////////////////////////////////////

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: 'Proxima Nova', sans-serif;
  line-height: 1;
  letter-spacing: -0.0125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-text, #282828);
  background-color: var(--color-bg);
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  min-width: 300px;
  backface-visibility: hidden;
  min-height: 100%;
  height: 100%;

  &[data-modal] {
    overflow: hidden;
  }
}

b, strong {
  font-weight: 700;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

img {
  max-width: 100%;
  height: auto;
  border: none;
  display: inline-block;
}

a {
  text-decoration: none;

  &:focus, &:hover, &:active {
    outline: 0;
  }
}

::-moz-selection {
  background: var(--color-red, #ee4256);
  color: #fff;
  text-shadow: none;
}

::selection {
  background: var(--color-red, #ee4256);
  color: #fff;
  text-shadow: none;
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
  display: block;
  box-sizing: border-box;
}

:focus {
  outline: none;
}

*:before,
*:after {
  box-sizing: border-box;
}

input,
select,
button,
textarea {
  box-sizing: border-box;
  font-family: inherit;
}

button,
label {
  cursor: pointer;
  font-size: 100%;
}

button {
  border: 0;
  background-color: transparent;
  padding: 0;
}

input {
  appearance: none;
  -webkit-appearance: none;
}

a,
button {
  @media screen {
    transition: all .2s ease-in-out;
  }
}

.is-center {
  text-align: center;
}

.container {
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 3%;

  @media screen and (min-width: 1801px) {
    padding: 0 60px;
  }
  
  @media screen and (max-width: 800px) {
    /*overflow-x: hidden;*/
  }

  &.-flex {
    display: flex;
  }

  &.container-artist.-flex {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media screen and (min-width: 1052px) {
      flex-direction: row;
    }
  }
}

.section.-p--n- {
  .container {
    @media screen and (max-width: 800px) {
      overflow-x: hidden;
    }
  }
}

.page {
  background-color: var(--color-page, #f5f5f5);
}

.main {
  display: flex;
  flex-direction: column;
  max-width: 100%;

  //.section--description {
  //  order: 1000;
  //}

  .section:empty {
    padding: 0;
  }
}

.-profile- {
  .main {
    flex-grow: 1;
  }
}

#page-nav {
  transform: translateX(-100%);
  position: fixed;
  z-index: 10000;
  display: block;
  top: 0;
  left: 0;
  width: 300px;
  height: 100%;
  background-color: #232323;
  transition: all .2s ease-in-out;

  button {
    position: absolute;
    bottom: 100px;
    left: 100%;
    background-color: var(--color-olb-blue, #255BE0);
    width: 40px;
    height: 40px;

    svg {
      width: 20px;
      height: 20px;
      fill: #fff;
      vertical-align: middle;
      position: relative;
      top: -1px;
    }
  }

  &.is-open {
    transform: translateX(0);
  }

  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    height: 100%;

    aside {
      max-height: 100%;
      overflow-y: scroll;
      scroll-behavior: smooth;
      width: calc(100% + 20px);
      padding: 20px 20px 0 0;
    }
  }

  a {
    display: block;
    padding: 12px 20px;
    font-size: 15px;
    color: #fff;
    transition: all .2s ease-in-out;

    &:hover {
      background-color: var(--color-olb-blue, #255BE0);
    }

    &.is-checked {
      background-color: var(--color-olb-blue, #255BE0);
    }
  }

}

.-tb- {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}


@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

