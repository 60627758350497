:root {
  --color-bg: #F8F9FB;
  --color-border: #F1F4FA;
  --color-brdr: #DDE1E9;
  --color-shadow: #D1D6E2;
  --color-shadow-rgb: 209, 214, 226;
  --color-primary: #ee4256;
  --color-primary-hover: #e42d42;
  --color-primary-rgb: 238, 66, 86;
  --color-green: #4CB061;
  --color-green-rgb: 76, 176, 97;
  --color-shadow-rbg: 209, 214, 226;
  --color-title: #011031;
  --color-title-rgb: 1, 16, 49;
  --color-text: #282828;
  --color-text-rgb: 40, 40, 40;
  --color-text-light: #666C7D;
  --color-text-light-rgb: 109, 116, 130;
  --color-white: 255, 255, 255;
  --color-logo: #fff;

  --color-check: transparent;

  --offset-xx: 9px;
  --offset-x: 18px;
  --offset-sx: 21px;
  --offset-xs: 24px;
  --offset-xl: 28px;
  --offset-xm: 32px;
  --offset-s: 36px;
  --offset-ss: 48px;
  --offset-sm: 54px;
  --offset-ms: 56px;
  --offset-m: 72px;
  --offset-ml: 60px;
  --offset-l: 144px;

  --radius-x: 3px;
  --radius-s: 4px;
  --radius-m: 9px;
  --radius-l: 10px;

  --header-height: 62px;

  @media screen and (min-width: 801px) {
    --header-height: 80px;
  }
}




/*  Sizes

16px = 100%

18px = 112.5%

21px = 131.25%

24px = 150%

30px = 187.5%

60px = 375%

*/
