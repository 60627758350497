.section--404 {
  text-align: center;

  svg {
    margin-bottom: var(--offset-s);
    max-width: 100%;
    height: auto;
  }

  .h3 {
    margin-bottom: 0;
    font-weight: 900;
    line-height: 1.2;
  }
}
