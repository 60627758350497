.section {
  &-example-venue {
    .card--line {
      &:nth-of-type(2) {
        .card--line-time {
          &:nth-of-type(3) ~ .card--line-time {
            display: none;
          }
        }
      }
      &:nth-of-type(3) {
        .card--line-time {
          &:nth-of-type(6) ~ .card--line-time {
            display: none;
          }
        }
      }
    }
  }

  &-archive {
    &--wrap {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      @media screen and (min-width: 961px) {
        flex-direction: row;
      }
    }

    &--aside {
      width: 100%;
      position: sticky;
      top: var(--header-height);
      will-change: transform;
      transition: transform 0.2s;
      z-index: 5;

      //@media screen and (min-width: 801px) {
      //  max-width: 60px;
      //  flex-shrink: 0;
      //  margin-right: var(--offset-x);
      //  position: sticky;
      //  top: var(--offset-x);
      //}
      @media screen and (min-width: 961px) {
        top: calc(var(--offset-s) + var(--header-height));
        max-width: 230px;
        margin-right: var(--offset-xs);
      }
      @media screen and (min-width: 1281px) {
        margin-right: var(--offset-m);
      }
    }

    &--content {
      flex-grow: 1;
      max-width: 100%;
      width: 100%;
      position: relative;

      //@media screen and (min-width: 801px) {
      //  max-width: calc(100% - var(--offset-x) - 60px);
      //}
      @media screen and (min-width: 961px) {
        max-width: calc(100% - var(--offset-xs) - 230px);
      }
      @media screen and (min-width: 1281px) {
        max-width: calc(100% - var(--offset-m) - 230px);
      }
    }

    &--header {
      display: flex;
      justify-content: space-between;
      padding: var(--offset-x) var(--offset-xx) var(--offset-xx);
      align-items: center;
      background-color: var(--color-bg);
      position: sticky;
      top: 0;
      will-change: transform;
      transition: transform 0.2s;
      z-index: 5;
      width: calc(100% + 18px);
      margin: var(--offset-s) -9px var(--offset-x);

      &:first-of-type {
        margin-top: 0;
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        height: 1px;
        background-color: var(--color-shadow);
        left: 9px;
        right: 9px;
      }

      .h4 {
        margin-bottom: 0;
        flex-grow: 1;
        font-size: 112.5%;
      }

      @media screen and (min-width: 375px) {
        padding: var(--offset-x) var(--offset-xx) var(--offset-xx);
      }

      @media screen and (min-width: 801px) {
        margin-bottom: var(--offset-s);
        padding: var(--offset-x) var(--offset-xx) var(--offset-xx);

        .h4 {
          font-size: 150%;
        }
      }
    }

    &--sub-header {
      position: sticky;
      top: 0;
      z-index: 15;
      margin-bottom: 95px;

      & + .section-archive--header {
        margin-top: -95px;
      }

      &[hidden] {
        display: flex;
      }
    }

    &--sort {
      position: absolute;
      top: 22px;
      right: 0;
      font-size: 9px;

      @media screen and (min-width: 375px) {
        font-size: 12px;
        top: 18px;
      }
      @media screen and (min-width: 801px) {
        font-size: 16px;
      }
    }

    &--list {
      & + .section-archive--header {
        margin-top: var(--offset-m);
      }
    }
  }

  &-list-tax {
    .card {
      &--line-head {
        align-self: flex-start;
        padding-top: 44px;
        font-size: 60px;
      }

      &--line-list {
        a {
          color: var(--color-text-light);

          @media screen {
            &:hover {
              color: var(--color-title);
            }
          }
        }
      }

      &:nth-of-type(2) {
        .card--line-list {
          li:nth-of-type(2) {
            & ~ li {
              display: none;
            }
          }
        }
      }
      &:nth-of-type(3) {
        .card--line-list {
          li:nth-of-type(7) {
            & ~ li {
              display: none;
            }
          }
        }
      }
      &:nth-of-type(4) {
        .card--line-list {
          li:nth-of-type(11) {
            & ~ li {
              display: none;
            }
          }
        }
      }
    }
  }

  &--profile {
    .card--fave {
      display: inline-flex;
      transform: none;
      opacity: 1;
    }
  }
}

.list {
  &-column {
    li {
      width: 100%;
      /*display: inline-block;*/
      vertical-align: top;

	  &.item-article {
      font-family: Proxima Nova;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
    margin-bottom: 12px;
	  }
  }

	li:not(.item-article) {
	  display: inline-block;
	  font-weight: 700;
      line-height: 1.44;
      margin-bottom: var(--offset-x);
	}

    a {
      color: var(--color-text-light);

      @media screen {
        &:hover {
          color: var(--color-primary);
        }
      }
    }

    span {
      color: var(--color-text-light);
    }
  }

  &-column-count {
    li {
      width: 100%;
      display: inline-block;
      vertical-align: top;
    }
    @media screen and (min-width: 1052px) {
      column-count: 2;
      column-gap: var(--offset-x);
    }
  }

  &-column-flex {
    display: flex;
    flex-wrap: wrap;

    li:not(.item-article) {
      width: 50%;
      padding-right: var(--offset-x);
    }
  }

  &-column--faves {
    li {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      transition: all 0.2s;
      border-radius: var(--radius-l);

      @media screen and (min-width: 1052px) {
        padding: 0 0 0 18px;

        &:hover {
          box-shadow: inset 0 0 0 1px var(--color-shadow);

          button {
            opacity: 1;
          }
        }
      }
    }

    a {
      flex-grow: 1;
      max-width: calc(100% - 55px);
    }

    button {
      flex-shrink: 0;
      margin-right: -9px;

      @media screen and (min-width: 1052px) {
        margin-right: 0;
        opacity: 0;
      }
      @media screen {
        &:hover {
          color: var(--color-primary);
        }
      }
    }
  }
}

.blur{
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  background: #000;
  opacity: 0.4;
  z-index: 100;
}

.transfers-only-whole-words {
  word-break: break-word;
}

.cut-to-size {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

h2,
h3,
h4,
h5,
.h2,
.h3,
.h4,
.h5 {
  font-weight: 700;
  color: var(--color-title);
}

.h1 {
  font-size: 131.5%;
  line-height: 1.35;
  font-weight: 900;
  color: var(--color-title);
  margin-bottom: var(--offset-x);

  @media screen and (min-width: 801px) {
    font-size: 150%;
  }

  @media screen and (min-width: 961px) {
    font-size: 175%;
  }

  @media screen and (min-width: 1281px) {
    font-size: 200%;
  }

  @media screen and (min-width: 1441px) {
    font-size: 250%;
  }

  @media screen and (max-width: 600px) {
    &.-is-desc- {
      border-radius: 10px 10px 0 0;
      background-color: var(--color-title);
      color: #fff;
      padding: var(--offset-x);
    }
  }
}
.h2 {
  //font-size: 38px;
  font-size: 237.5%;
  line-height: 1.25;
  font-weight: 700;
}
.h3 {
  //font-size: 28px;
  font-size: 175%;
  line-height: 1.1;
  font-weight: 700;
}
.h4 {
  //font-size: 24px;
  font-size: 150%;
  line-height: 1.25;
  font-weight: 700;
}
.h5 {
  //font-size: 24px;
  font-size: 112.5%;
  line-height: 1.67;
  font-weight: 700;
}

.-is-upper- {
  text-transform: uppercase;
}

.style-content {
  color: var(--color-text-light);
  line-height: 175%;

  & > div,
  p,
  ul,
  ol {
    margin-bottom: var(--offset-x);

    @media screen and (min-width: 961px) {
      margin-bottom: var(--offset-s);
    }
  }

  h2,
  .h2 {
    padding-top: var(--offset-xs);
    margin-bottom: 0.5em;
    font-size: 150%;

    @media screen and (min-width: 961px) {
      padding-top: var(--offset-s);
      font-size: 200%;
    }
    @media screen and (min-width: 1281px) {
      font-size: 250%;
    }
  }

  h3 {
    padding-top: var(--offset-x);
    margin-bottom: var(--offset-xx);
    font-size: 112.5%;

    @media screen and (min-width: 961px) {
      //padding-top: var(--offset-s);
      margin-bottom: var(--offset-x);
    }
  }

  p {
    max-width: 880px;
  }

  & > * {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .card-event--tags {
    margin-bottom: 0;
  }

  ol {
    list-style: decimal inside;
  }

  ul {
    list-style: disc inside;
  }

  a {
    color: var(--color-primary);
    @media screen {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .-notice- {
    max-width: 880px;
  }
}

.-desc-intro- {
  //margin-bottom: var(--offset-x);
  //display: flex;
  //flex-wrap: wrap;

  color: var(--color-text-light);
  margin-bottom: var(--offset-x);

  dl {
    display: flex;

    & + dl {
      margin-top: var(--offset-xx);
    }
  }
  dt {
    width: 120px;
    flex-shrink: 0;
  }
  dd {
    flex-grow: 1;
    margin-left: var(--offset-x);

    a {
      color: var(--color-primary);

      @media screen {
        &:hover {
          color: var(--color-title);
          text-decoration: underline;
        }
      }
    }
  }
}

.breadcrumbs-wrapper {
  order: 100;

  @media screen and (min-width: 371px) {
    background-color: var(--color-title) ;
    order: 0;
  }
}

.breadcrumbs {
  //padding: 36px 0 18px;
  padding-top: 12px;
  padding-bottom: 12px;
  line-height: 1.75;
  color: rgba(var(--color-text-rgb), 0.5);
  order: 100;
  display: block;

  .card--d {
    margin: 0 8px;
  }

  button,
  a {
    color: currentColor;

    @media screen {
      &:hover {
        color: var(--color-primary);
      }
    }
  }

  button {
    border-bottom: 1px solid;
    margin-left: 8px;
    position: relative;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    span {
      display: none;

      &:last-child {
        display: initial;
      }
    }
  }

  &.-is-light- {
    color: rgba(var(--color-text-rgb), 0.5);

    @media screen and (min-width: 371px) {
      color: #fff;
    }
  }

  &[data-view="hide"] {
    a {
      &:nth-of-type(7) {
        & ~ span,
        & ~ a {
          display: none;
        }
      }
    }
    button span {
      display: none;

      &:first-child {
        display: initial;
      }
    }
  }

  @media screen and (min-width: 371px) {
    //&[hidden] {
    //  display: block;
    //}
    order: 0;
  }
}

.-tb-season-bg {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000000;
  background: rgba(#000, 0.7);
}

.-tb-widget- {
  position: fixed;
  top: 0;
  width: 100%;
  right: 100%;
  height: 100%;
  opacity: 0;
  max-width: 460px;
  z-index: 10000001;

  &[data-show] {
    right: 0;
    opacity: 1;

    & + .-tb-season-bg {
      display: block;
    }
  }
}


.card--title-strong {
  font-weight: 700;
}

@media screen and (max-width: 600px) {
  .-slider-.slider--cls-fix[data-type=multiple] .card {
    margin-bottom: 0;
  }
}

.slider--cls-fix {
  .-slider--thumbs-,
  .-slider--thumbs-[style] {
    overflow: hidden!important;
    // max-height: 182px;
    // aspect-ratio: 5;

    .card--big,
    .card--normal {
      float: left;
      width: min-content;
    }

  }

  @media screen and (min-width: 601px) {
    .-slider--thumbs-,
    .-slider--thumbs-[style] {
      max-height: 280px;
    }
    }
}
