.filter--secondary {
  display: flex;
  padding: var(--offset-x) 0;

  .dropdown {
    flex-shrink: 0;
    margin-left: var(--offset-x);

    &:first-of-type {
      margin-left: 0;
    }

    width: calc(70% - 18px);

    &:first-of-type {
      width: 30%;
    }

    &:nth-child(2) ~ .dropdown {
      opacity: 0;
      visibility: hidden;
      max-width: 0;
      overflow: hidden;
    }

    .form--line {
      padding: 0 var(--offset-x);
    }
  }

  @media screen and (min-width: 801px) {

    .dropdown {
      width: calc((100% - 36px) / 3);

      &:first-of-type {
        width: calc((100% - 36px) / 3);
      }

      &:nth-child(2) + .dropdown {
        opacity: 1;
        visibility: visible;
        max-width: initial;
        overflow: visible;
      }
      &:nth-child(3) ~ .dropdown {
        opacity: 0;
        visibility: hidden;
        max-width: 0;
        overflow: hidden;
      }

    }
  }

  @media screen and (min-width: 961px) {
    padding-top: var(--offset-s);
    padding-bottom: 0;

    .dropdown {
      width: calc((100% - 280px - 36px) / 2);

      &:first-of-type {
        width: 280px;
      }
    }

  }

  @media screen and (min-width: 1052px) {
    .dropdown {
      width: calc((100% - 380px - 36px) / 2);

      &:first-of-type {
        width: 380px;
      }
    }

  }

  @media screen and (min-width: 1281px) {
    .dropdown {
      width: calc((100% - 380px - 54px) / 3);

      &:nth-child(3) ~ .dropdown {
        opacity: 1;
        visibility: visible;
        max-width: initial;
        overflow: visible;
      }

    }
  }

  &.-seans- {
    // color: #79b9e7;
    .dropdown {
      @media screen and (max-width: 800px) {
        &:first-of-type {
          min-width: 250px;
          width: 100%;
        }
      }
    }
  }

}

.dropdown {
  position: relative;
  display: flex;
  will-change: max-height, border, margin, padding;
  transition: all .2s;

  &--trigger {
    position: relative;
    width: 100%;

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0 var(--offset-x);
      font-size: 100%;
      color: var(--color-text-light);
      position: relative;

      svg {
        fill: currentColor;
        margin-left: 18px;
      }

    &.-footer- {
      svg {
        @media screen and (max-width: 650px) {
          margin-left: 8px;
        }
      }

		  @media screen and (max-width: 650px) {
        justify-content: center;
        background: #EE4256;
        width: 100%;
        height: 60px;
        border: 2px solid var(--color-primary);
        border-radius: 8px;
        line-height: 60px;
      }
    }

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 1;
      }

      @media screen {
        &:hover {
          color: var(--color-title);
        }
      }
    }

    &-clear {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      z-index: 2;
      top: 50%;
      max-width: 30px;
      height: 30px;
      margin-top: -15px;
      right: 46px;
      width: 100%;
      border-radius: var(--radius-s);
      color: #fff;
      background-color: var(--color-title);

      &.-trigger- {
        position: absolute;
      }

      span,
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: opacity .2s;
      }

      span {
        font-size: 87.5%;
        white-space: nowrap;
      }

      svg {
        width: 12px;
        height: 12px;
        fill: currentColor;
        opacity: 0;
      }

      &.-is-long- {
        max-width: 100px;
      }

      &.-is-fix- {
        span {
          display: none;
        }
        svg {
          opacity: 1;
        }
      }

      @media screen {
        &:hover {
          background-color: var(--color-primary);

          span {
            opacity: 0;
          }

          svg {
            opacity: 1;
          }
        }
      }

      @media screen and (max-width: 1051px) {
        span {
          opacity: 0;
        }
        svg {
          opacity: 1;
        }
        &.-is-long- {
          max-width: 30px;
        }
      }
    }

  }

  &--popup {
    display: none;
    position: absolute;
    top: 100%;
    right: 50%;
    min-width: 100%;
    transform: translateX(50%);
    border-radius: var(--radius-l);
    will-change: max-height;
    border: 1px solid var(--color-shadow);
    box-shadow: 0 4px 4px var(--color-shadow);
    background-color: #fff;
    padding: 16px 0;
    z-index: 10;

    &-list {
      width: 100%;

      .form--line {
        margin-bottom: 0;
      }
    }
  }

  &[data-view="show"] {
    &.dropdown-filter {
      .dropdown--trigger-item {
        font-weight: 600;
        background-color: rgba(var(--color-title-rgb), .03);

        svg {
          transform: rotate(-90deg);
        }
      }
    }
    .dropdown--trigger-item {
      color: var(--color-title);

      svg {
        transform: rotate(-90deg);
      }
    }
    .dropdown--popup {
      display: flex;
    }
  }

  &--links {
    .dropdown {
      &--trigger-item {
        font-weight: 700;
        padding: 0;
        color: var(--color-title);

        &.-footer- {
          @media screen and (max-width: 650px) {
            color: #FFFFFF;
          }
        }
      }

      &--popup {
        top: calc(100% + 8px);

        a {
          display: block;
          padding: var(--offset-xx) var(--offset-x);
          font-size: 121.25%;
          color: var(--color-title);

          @media screen {
            &:hover {
              color: var(--color-primary);
            }
          }
        }
      }
    }
  }

  &.-selected- {
    .dropdown--trigger-clear {
      opacity: 1;
      visibility: visible;
    }
  }

  &-normal {
    .dropdown--popup-list {
      overflow-y: scroll;
      scroll-behavior: smooth;
      overscroll-behavior-y: none;
      padding: 2px 0;
      width: 100%;
      max-height: 200px;

      @media screen and (min-width: 1052px) {
        max-height: 480px;
      }
    }
  }

  &.dropdown--date-event {
    position: static;

    .dropdown--popup {
      top: -2px;
      left: -2px;
      right: -2px;
      transform: none;

      &:before {
        display: none;
      }
    }
  }
}

footer .dropdown-normal .dropdown--popup-list {
  @media screen and (max-width: 650px) {
	 text-align: center;
  }
}

.-scrolling- {
  overflow-y: scroll;
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.-is-link-list- {
  max-height: 220px;
  width: 100%;

  @media screen and (min-width: 1052px) {
    max-height: 360px;
  }
}

.dropdown--normal {
  .dropdown {
    &--trigger {
      height: 52px;

      &-item {
        border-radius: var(--radius-l);
        border: 1px solid var(--color-shadow);
        box-shadow: 0 4px 4px var(--color-shadow);
        background-color: #fff;
        font-weight: 700;
        height: 52px;
      }
    }
    &--popup {
      top: calc(100% + 12px);

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: -9px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        background-color: #fff;
        border: 1px solid var(--color-shadow);
        width: 17px;
        height: 17px;
        border-right: 0;
        border-bottom: 0;
      }
    }
  }
}

/*

.filter-primary {
  border: 1px solid var(--color-shadow);

  .dropdown {
    &:first-child ~ .dropdown {
      border-top: 1px solid var(--color-shadow);
    }
  }

  .dropdown--trigger {
    height: 60px;

    &-item {
      height: 60px;
    }
  }
}
*/

.filter--primary {

  .dropdown {

    &:first-of-type {
      .dropdown--trigger-item {
        border-radius: var(--radius-l) var(--radius-l) 0 0;
      }
      & ~ .dropdown {
        border-top: 1px solid var(--color-shadow);
      }
    }

    &:last-of-type {
      .dropdown--trigger-item {
        border-radius: 0 0 var(--radius-l) var(--radius-l);
      }
    }

  }

  .dropdown--trigger {
    height: 60px;

    &-item {
      height: 60px;
    }
  }

  &-wrap {
    max-height: 0;
    overflow: hidden;
    opacity: 0;

    @media screen and (max-width: 1051px) {
      position: fixed;
      width: 100%;
      //top: 0;
      left: 0;
      height: 100%;
      background: var(--color-bg);
      z-index: 100;
      max-height: 0;
      bottom: 0;
      box-shadow: -4px 0px 10px #000;
      border-radius: 10px 10px 0 0;
    }
  }

  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    background-color: rgba(#fff, .7);
  }

  &-mng {
    margin-left: -9px;
    margin-right: -9px;
    width: calc(100% + 18px);
    padding: 0 var(--offset-x);
    height: 60px;
    background-color: rgba(#fff, .6);
    text-align: center;
    border-radius: var(--radius-l);
    margin-top: var(--offset-x);

    button {
      height: 48px;
      border-radius: 10px;
      font-weight: 900;
      color: #fff;
      background-color: var(--color-primary);
      min-width: 240px;
      padding: 0 var(--offset-x);
      margin-top: 6px;

      &:first-of-type {
        & ~ button {
          display: none;
        }
      }
    }
  }

  &[data-view="show"] {
    @media screen and (max-width: 1051px) {
      .filter--primary {
        &-wrap {
          max-height: calc((100% - var(--header-height)) * 0.95);
          opacity: 1;
          overflow-y: scroll;
          scroll-behavior: smooth;
          overscroll-behavior-y: none;
        }
      }
      &[data-is-single] .filter--primary-mng {
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        margin: 0;
      }
      &[data-is-multiple] .filter--primary-mng {
        button {
          &:first-of-type {
            display: none;

            & ~ button {
              display: initial;
            }
          }
        }
      }

    }
  }

  &-trigger {

  }

  &-header {
    position: sticky;
    top: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--offset-x);
    height: 62px;
    box-shadow: 0 2px 4px var(--color-shadow);
    padding: 0 0 0 var(--offset-x);
    background-color: #fff;

    .h5 {
      font-weight: 900;
    }
  }

  &-footer {
    margin-top: var(--offset-x);
    position: sticky;
    bottom: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    padding: 0 5%;
  }

  @media screen and (min-width: 1052px) {
    &-footer,
    &-header,
    &-mng {
      opacity: 0;
      max-height: 0;
      overflow: hidden;
      margin: 0;
    }
    &-wrap {
      background-color: #fff;
      max-height: initial;
      overflow: visible;
      border: 1px solid var(--color-shadow);
      border-radius: var(--radius-l);
      box-shadow: 0 2px 4px var(--color-shadow);
      opacity: 1;
    }


    .dropdown {
      position: static;

      &--popup {
        top: 0;
        left: calc(230px + var(--offset-s));
        transform: none;
        width: calc(90vw - 230px - var(--offset-s));
        min-height: 100%;
        max-height: calc(100vh - var(--offset-s) - var(--header-height));
        overflow-y: auto;
        overscroll-behavior-y: none;
        flex-direction: column;
        justify-content: flex-start;
        padding: var(--offset-xs) 0;
        right: initial;
        //transition: max-height .2s;
      }
    }
  }

  @media screen and (min-width: 1281px) {
    .dropdown {
      &--popup {
        left: calc(230px + var(--offset-m));
        width: calc(90vw - 230px - var(--offset-m));
      }
    }
  }

  @media screen and (max-width: 1051px) {
    .dropdown {
      flex-direction: column;

      &--trigger {
        z-index: 1;
        background-color: var(--color-bg);
        position: sticky;
        top: 62px;

        &-item {
          font-weight: 600;
        }
      }

      &[data-view="show"] {
        .dropdown--trigger-item {
          box-shadow: 0 2px 4px var(--color-shadow);
        }
      }

      &--popup {
        position: relative;
        border: none;
        box-shadow: none;
        border-radius: 0;
        background-color: var(--color-bg);
        transform: none;
        z-index: 0;
        min-width: initial;
        top: initial;
        right: initial;
        flex-direction: column;
        padding: 0;

        &-list {
          overflow: initial;
          max-height: initial;
        }
      }

    }
    .dropdown--date {
      .dropdown--popup-list {
        margin: 0 auto;
      }
    }
  }

  @media screen and (min-width: 1804px) {
    .dropdown {
      &--popup {
        width: 1318px;
      }
    }
  }

  .-dp- {
    &-price- {
      justify-content: center;

      .dropdown--popup-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: var(--offset-xs) var(--offset-x);
      }

      .form--group {
        display: flex;
        flex-direction: column;
        margin-bottom: var(--offset-xs);

        &:first-child label {
          margin-left: 18px;
        }

        & + .form--group {
          margin-left: var(--offset-x);
        }

        @media screen and (min-width: 1052px) {
          margin-bottom: var(--offset-s);
        }
        @media screen and (min-width: 415px) {
          flex-direction: row;
          align-items: center;

          &:first-child label {
            margin-left: 0;
          }

          & + .form--group {
            margin-left: 0;
          }
        }

        input {
          width: 120px;
          padding: 0 var(--offset-x);

          @media screen and (min-width: 415px) {
            width: 140px;
          }

          @media screen and (min-width: 601px) {
            width: 140px;
            padding: 0 var(--offset-x);
          }
        }

        &:first-child {
          label {
            padding-left: 0;
          }
        }
      }

      .form--footer {
        width: 100%;
        flex-shrink: 0;

        @media screen and (max-width: 600px) {
          .btn--primary-border,
          .btn--primary {
            min-width: initial;
            padding: 0 var(--offset-x);
          }
        }
      }
    }
  }
}

.-dp- {
  &-wrap {
    display: flex;

  }
  &-header {
    position: sticky;
    top: -24px;
    background-color: #fff;
    z-index: 2;
    display: flex;
    margin-top: -24px;
    justify-content: flex-end;
    padding: var(--offset-xs) var(--offset-m) var(--offset-x) 0;
    margin-bottom: var(--offset-s);
    border-bottom: 1px solid var(--color-border);
  }

  .quick-search-form input {
    border-bottom-color: transparent;
  }

  &-footer {
    position: sticky;
    bottom: -24px;
    background-color: #fff;
    z-index: 2;
    padding-top: var(--offset-s);
    padding-bottom: var(--offset-xs);
    margin-top: auto;
    margin-bottom: -24px;
    display: flex;
    justify-content: center;
    border-top: 1px solid var(--color-border);

    .btn--primary-border {
      margin-right: var(--offset-x);
    }

  }
  &-body {
    margin-bottom: var(--offset-xx);
    padding: 0 var(--offset-xx);

    [data-hide] {
      max-height: 0;
      padding: 0;
      margin: 0;
      opacity: 0;
      overflow: hidden;
    }

    &.-columned- {
      @media screen and (min-width: 801px) {
        column-count: 2;
        column-gap: var(--offset-s);
      }
      @media screen and (min-width: 1052px) {
        padding: 0 var(--offset-m);
        margin-bottom: var(--offset-xs);
      }
    }

  }

  @media screen and (max-width: 1051px) {
    &-header {
      top: 122px;
      padding: var(--offset-xx) var(--offset-x);
      margin: 0 0 var(--offset-x);
      justify-content: stretch;
    }
    &-footer {
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      border: none;
      padding: 0;
      margin: 0;
    }
  }
}

.-checkbox-ab- {
  display: flex;
  width: 100%;
  //width: calc(50% - var(--offset-xx));
  margin-bottom: var(--offset-x);
  align-items: flex-start;
  line-height: 1.3;
  color: var(--color-text-light);
  padding: 0 var(--offset-xx);
  text-align: left;
  position: relative;

  &:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    content: '';
  }

  svg {
    flex-shrink: 0;
    margin-right: var(--offset-xx);
  }

  &.-checked- {
    color: var(--color-text);
  }

  @media screen {
    &:hover {
      color: var(--color-text);
    }
  }

}

.-is-link- {
  line-height: 1.35;
  padding: 0 var(--offset-sx);

  & + .-is-link- {
    margin-top: 18px;
  }

  a {
    color: var(--color-text);

    @media screen and (min-width: 1052px) {
      &:hover {
        color: var(--color-primary);
      }
    }
  }

  small {
    font-weight: 700;
    display: block;
  }

  .card--d {
    margin: 0 8px;
  }

  &-ml- {

  }

}

[data-filter-body='show'] {
  body {
    overflow: hidden;
  }

  .header {
    visibility: hidden;
  }
}

@media screen and (max-width: 1051px) {
  [data-filter-open='show'] {
    body {
      overflow: hidden;
    }

    //.header.animated {
    //  animation-name: slideDown;
    //}
    //
    //&[data-filter-body='show'] {
    //  .filter--primary {
    //    .dropdown[data-view="hide"] {
    //      max-height: 0;
    //      overflow: hidden;
    //      padding: 0;
    //      border: none;
    //      margin: 0;
    //    }
    //  }
    //}
  }
}

// Filter on the artists page

  @media screen and (max-width: 1051px) {
    .section--archive-aside.section--archive-aside-artist {
      position: fixed;
      bottom: 0;
    }
  }

.container-artist .filter--primary-header {
  margin-bottom: 0;
}

.filter-item {
  height: 40px;

  &.active .filter-item-link {
    font-weight: 600;
    background-color: rgba(var(--color-title-rgb), 0.03);
    color: var(--color-title);
  }
}

.filter-item-link {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 var(--offset-x);
  font-size: 100%;
  color: var(--color-text-light);
}

