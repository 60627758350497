.section--auth {

}

.auth- {

  &-wrap {
    display: flex;
    justify-content: center;

    .turnstil-captcha {
      width: 300px;
      margin: 0px auto;
    }
  }

  &-title {
    color: var(--color-title);
    font-size: 40px;
    font-weight: 800;

    span {
      color: var(--color-primary);
    }
  }

  &-list {
    padding-top: 12px;

    li {
      margin-top: 24px;
      display: flex;
      width: 100%;
      align-items: center;
      color: var(--color-title);
      line-height: 1.5;
      font-weight: 600;

      & > div {
        width: 42px;
        height: 42px;
        border: 1px solid var(--color-shadow);
        border-radius: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: var(--offset-s);
      }

      svg {
        fill: currentColor;
      }

      & > span {
        flex-grow: 1;
        max-width: calc(100% - 78px);
      }

    }
  }

  &-aside {
    width: 380px;
    margin-right: var(--offset-ml);

    @media screen and (min-width: 1052px) {
      display: block;
    }
  }

  &-form {
    width: 100%;
    flex-shrink: 0;
    border-radius: var(--radius-l);
    box-shadow: 4px 4px 10px var(--color-shadow);
    background-color: #fff;
    padding: var(--offset-s) var(--offset-x);
    position: relative;
    .form--hint-error{
      color: #ee4256;
    }
    @media screen and (min-width: 415px) {
      max-width: 560px;
    }

    @media screen and (min-width: 601px) {
      padding: var(--offset-s);
    }

    @media screen and (min-width: 1281px) {
      padding: var(--offset-s) var(--offset-m);
    }

    .-notice--done- {
      margin-top: var(--offset-x);
    }

    .form--footer {
      display: flex;
      flex-direction: column;
      align-items: center;

      button {
        margin-left: 0;
        flex-shrink: 0;
      }
      
      .reset-code {
        margin: var(--offset-x) 0 var(--offset-xx);

        &:hover {
          color: var(--color-primary);

          .line-timer {
            background-color: var(--color-primary);
          }
        }
      }

      .link--btn {
        &:disabled:hover {
          color: #6d6d6d;
        }
      }

      .line-timer {
        height: 2px;
        background-color: black;
        padding: 0;
        width: 0;
        transition: width 0.9s;
        position: absolute;
        left: 0;
        top: 0;

        &.active {
          background-color: var(--color-primary);
        } 

        &__wrapper {
          background-color: #6d6d6d;
          height: 2px;
          position: relative;
          padding: 0;
          width: 100%;
        }
      }

      .form--hint {
        padding-top: 0;
      }
    }

    &[data-login="mail"] {
      [data-t-phone] {
        display: none;
      }
    }

    &[data-login="phone"] {
      [data-t-mail] {
        display: none;
      }
    }

    &[data-loading="1"] {
      .form--loading {
        display: flex;
      }
    }

    &[data-step="0"] .form--footer {
      max-height: 60px;
      overflow: hidden;
    }

    [data-password] {
      label span {
        display: none;
      }
    }

    &[data-new="0"] {
      [data-password] {
        .form--hint {
          padding: 0;
          max-height: 0;
          overflow: hidden;
          margin: 0;
          opacity: 0;
        }
      }
      label span:first-child {
        display: initial;
      }
    }
    &[data-new="1"] {
      [data-password] label span:last-child {
        display: initial;
      }
    }

    [data-view="hide"] {
      display: none;
    }

    [data-banned] {
      background-color: var(--color-primary);
      color: white;
      font-weight: 900;
      font-size: 18px;
      border-radius: var(--radius-l);
    }
  }

}

.-af- {
  &-title- {
    font-size: 112.5%;
    font-weight: 700;
    text-align: center;
    margin-bottom: var(--offset-xs);
    @media screen and (min-width: 601px) {
      font-size: 150%;
    }
  }

  &-label- {
    flex-shrink: 0;
    line-height: 1.35;
    width: 100%;
    text-align: center;
    padding-bottom: var(--offset-x);

    @media screen and (min-width: 530px) {
      margin-left: 0;
      margin-right: auto;
      width: 170px;
      text-align: left;
      padding-bottom: 0;
    }
  }

  &-or- {
    width: 100%;
    flex-shrink: 0;
    text-align: center;
    padding: 24px 0;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      height: 1px;
      top: 50%;
      width: 100%;
      z-index: 1;
      background-color: var(--color-shadow);
    }

    b {
      padding: 0 var(--offset-s);
      display: inline-block;
      position: relative;
      z-index: 2;
      background-color: #fff;
    }
  }

  &-social- {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;

    @media screen and (min-width: 530px) {
      justify-content: flex-end;
    }

    &_btn {
      height: 52px;
      width: 68px;
      border-radius: var(--radius-l);
      flex-shrink: 0;
      border: 1px solid var(--color-shadow);
      color: var(--color-text);

      @media screen and (min-width: 601px) {
        width: 98px;
      }

      svg {
        fill: currentColor;
      }

      &:first-of-type {
        margin-right: var(--offset-x);
      }

      @media screen {
        &:hover {
          color: var(--color-primary);
          box-shadow: 0 4px 4px var(--color-shadow);
        }
      }
    }
  }
}

.-notice- {
  padding: var(--offset-sx);
  border-radius: var(--radius-l);
  line-height: 1.35;
  text-align: center;

  &-done- {
    background-color: rgba(0, 102, 0, 0.2);
  }

  &-progress- {
    background-color: rgba(var(--color-title-rgb), 0.4);
  }

  &-error- {
    background-color: var(--color-primary);
    color: #fff;
  }

  @media screen and (min-width: 601px) {
    text-align: left;
  }

}

.btn-auth-next:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}


