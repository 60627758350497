.section--subscribe {
  background-color: var(--color-title);
  color: #fff;
  padding: var(--offset-s) 0;
  order: 1001;
  position: relative;
  display: flex;
  min-height: 420px;

  &-title {
    color: #fff;
    font-size: 112.5%;
    line-height: 1.4;
    font-weight: 900;
    text-align: center;
    margin-bottom: var(--offset-xs);
    display: block;

    @media screen and (min-width: 400px) {
      font-size: 150%;
    }
    @media screen and (min-width: 601px) {
      font-size: 187.5%;
    }
    @media screen and (min-width: 801px) {
      font-size: 250%;
      margin-bottom: var(--offset-s);
    }
    @media screen and (min-width: 1052px) {
      font-size: 375%;
    }
  }

  &-form {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 0 auto var(--offset-x);
    max-width: 700px;
    flex-wrap: wrap;
    position: relative;

    label {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 6;
      transition: color .2s;

      svg {
        fill: #ee4256;
      }

      &[hidden] {
        display: none;
      }
    }

    input {
      height: 52px;
      border-radius: var(--radius-l) 0 0  var(--radius-l);
      flex-grow: 1;
      font-size: 100%;
      font-weight: 700;
      color: #fff;
      background-color: transparent;
      transition: all .2s;
      border: 1px solid #fff;
      border-right-color: transparent;
      padding: 0 var(--offset-xx);
      margin: 0;
      width: 100%;
      max-width: calc(100% - 68px);

      @media screen and (min-width: 701px) {
        max-width: calc(100% - 220px);
        font-size: 112.5%;
        padding: 0 var(--offset-x);
      }

      &:focus {
        background-color: #fff;
        color: var(--color-title);
      }
    }

    button {
      padding: 0;
      border-radius: 0 var(--radius-l) var(--radius-l) 0;
      background-color: var(--color-primary);
      font-weight: 900;
      font-size: 131.25%;
      color: #fff;
      height: 52px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 68px;

      svg {
        fill: currentColor;
      }

      span {
        display: none;
      }

      @media screen and (min-width: 701px) {
        font-size: 112.5%;
        width: 220px;

        svg {
          display: none;
        }
        span {
          display: initial;
        }
      }

      @media screen {
        &:hover {
          background-color: var(--color-primary-hover);
        }
      }
    }

    @media screen and (min-width: 701px) {
      margin-top: var(--offset-s);
    }

    @media screen and (max-width: 700px) {
      margin-top: var(--offset-x);
    }
  }

  .subscribe--btn {
    padding: 0;
    height: 52px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 100%;
    font-weight: 600;
    border: 1px solid #fff;
    color: #fff;
    border-radius: var(--radius-l);
    background-color: var(--color-title);

    &[data-subscribe="telegram"] svg {
      fill: #0088cc;
    }
    &[data-subscribe="viber"] svg {
      fill: #7D3DAF;
    }

    svg {
      margin-right: 12px;
    }

    @media screen and (min-width: 701px) {
      svg {
        width: 24px;
        height: 24px;
      }
    }

    @media screen {
      &:hover {
        color: var(--color-primary);
      }
    }
  }

  &-social {
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;

    a {

      & + a {
        margin-top: var(--offset-x);
      }

      @media screen and (min-width: 701px) {
        font-size: 112.5%;

        & + a {
          margin-top: var(--offset-s);
        }
      }
    }

    button {

      & + button {
        margin-top: var(--offset-x);
      }

      @media screen and (min-width: 701px) {
        font-size: 112.5%;

        & + button {
          margin-top: var(--offset-s);
        }
      }
    }

  }

  .btn--close {
    display: none;
    position: absolute;
    top: var(--offset-x);
    right: var(--offset-x);
    flex-shrink: 0;
    color: #fff;

    @media screen {
      &:hover {
        box-shadow: 0 0 4px var(--color-shadow);
      }
    }
  }

  .form--loading {
    color: #fff;
    background-color: rgba(#000, .6);
  }

  &-complete {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-title);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 25;

    &[hidden] {
      display: none;
    }

    svg {
      display: block;
      margin: 0 auto var(--offset-x);
    }

    .container {
      text-align: center;
    }

    .-message- {
      margin: 0 auto var(--offset-x);
      font-size: 112%;
      line-height: 1.35;
      max-width: 800px;

      &:empty {
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  @media screen and (min-width: 449px) {
    padding: var(--offset-sm) 0;
  }

  @media screen and (min-width: 801px) {
    padding: var(--offset-m) 0;
  }

  &.-is-modal- {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    display: flex;
    align-items: center;

    .btn--close {
      display: flex;
    }
  }

  &[data-loading="1"] {
    .form--loading {
      display: flex;
    }
  }

}

