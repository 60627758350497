.profile--row {
  margin-bottom: var(--offset-s);

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: 801px) {
    border-radius: var(--radius-l);
    box-shadow: 0 1px 4px var(--color-shadow);
    background-color: #fff;
    border: 1px solid var(--color-shadow);
    padding: var(--offset-x) var(--offset-sx);
  }

  @media screen and (min-width: 961px) {
    padding: var(--offset-sx) var(--offset-xs);
    margin-bottom: var(--offset-xs);
  }
  @media screen and (min-width: 1052px) {
    padding: var(--offset-s) var(--offset-s);
    margin-bottom: var(--offset-s);
  }
  @media screen and (min-width: 1281px) {
    padding: var(--offset-sm) var(--offset-m);
  }


  .h3 {
    margin-bottom: var(--offset-s);

    @media screen and (max-width: 800px) {
      font-size: 131.25%;
    }
  }

  &-social {
    li {
      display: flex;
      align-items: center;
      color: var(--color-title);

      & + li {
        margin-top: var(--offset-s);
      }


      svg {
        margin-right: var(--offset-x);
        fill: currentColor;
        width: 26px;
        flex-shrink: 0;

        @media screen and (min-width: 801px) {
          margin-right: var(--offset-s);
        }
      }

      img {
        max-width: 40px;
        max-height: 40px;
        margin-right: var(--offset-x);

        @media screen and (min-width: 801px) {
          max-width: 52px;
          max-height: 52px;
          margin-right: var(--offset-s);
        }
      }

      b {
        margin-bottom: 6px;
      }

      & > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: calc(100% - 102px);

        div {
          margin-bottom: 6px;
          font-weight: 700;
          color: var(--color-title);
        }
      }
    }
  }

  .pagination {
    @media screen and (min-width: 801px) {
      background-color: rgba(var(--color-white), 1);
    }
  }
}

.section--profile {
  padding: 0 0 var(--offset-x) 0;

  .section-archive--aside {
    overflow: hidden;
    height: 48px;
    margin-left: calc(-1 * 100% / 18 );
    width: calc(100% + 100% / 11.5);
    margin-bottom: var(--offset-s);

    @media screen and (min-width: 961px) {
      margin-left: 0;
      height: auto;
      margin-bottom: 0;
      overflow: initial;
    }
  }

  @media screen and (min-width: 601px) {
    padding: 0 0 var(--offset-xs) 0;
  }

  @media screen and (min-width: 961px) {
    padding: var(--offset-sm) 0 var(--offset-sm) 0;
  }

  .form--footer {
    padding-top: var(--offset-x);

    @media screen and (max-width: 960px) {
      button {
        min-width: initial;
        padding: 0 var(--offset-sx);
      }
    }
    @media screen and (min-width: 961px) {
      padding-top: 0;
    }
  }

}

.profile--nav {
  display: flex;
  flex-direction: row;
  padding: 0 0 0 calc(5% - var(--offset-x));
  height: 72px;
  overflow-x: auto;
  scroll-behavior: smooth;
  overscroll-behavior-x: none;
  background-color: var(--color-title);
  color: #fff;


  @media screen and (min-width: 961px) {
    padding: 0;
    height: auto;
    overflow: initial;
    flex-direction: column;
    box-shadow: 0 1px 4px var(--color-shadow);
    background-color: #fff;
    color: var(--color-text-light);
    border: 1px solid var(--color-shadow);
    border-radius: var(--radius-l);
    //padding: var(--offset-x) var(--offset-sx);
  }

  a {
    display: flex;
    align-items: center;
    height: 48px;
    color: currentColor;
    white-space: nowrap;
    padding-right: var(--offset-x);

    svg {
      fill: currentColor;
      margin: 0 var(--offset-x);
      width: var(--offset-x);
      //width: var(--offset-s);
    }

    &.-current- {
      font-weight: 700;
      color: var(--color-primary);
    }

    @media screen {
      &:hover {
        color: var(--color-primary);
      }
    }

    &:last-child {
      padding-right: var(--offset-s);
    }

    @media screen and (min-width: 961px) {
      padding-right: 0;
      height: 60px;

      &:hover {
        color: var(--color-title);
      }

      &.-current- {
        color: var(--color-title);
      }

      &:last-child {
        padding-right: 0;
      }
      & + a {
        border-top: 1px solid var(--color-shadow);
      }
    }

    //@media screen and (min-width: 801px) and (max-width: 1051px)  {
    //  span {
    //    display: none;
    //  }
    //}
  }

}

.ticket-buttons {
	display:flex;
	justify-content: space-between;
}

.click-qr {
	display:block;
	margin-top:58px;
	width:100%;
	margin-top: auto;
	margin-bottom: 0;
}

.button-qr, .button-barcode {
	width:42px;
	height:42px;
}

.button-qr {
	float:right;
}

.qr-w {
	width:100%;
	margin: auto;

	@media screen and (min-width: 961px) and (max-width: 1351px){
        width: 230px;
    }

	@media screen and (min-width: 1352px) {
        width: 250px;
    }

}
.profile--order {

  &-row {
    margin-bottom: var(--offset-x);
    padding: var(--offset-x);

    &:last-of-type {
      margin-bottom: 0;
    }

    @media screen and (min-width: 801px) {
      margin-bottom: var(--offset-xs);
      padding: var(--offset-x) var(--offset-sx);
    }
    @media screen and (min-width: 961px) {
      padding: var(--offset-sx) var(--offset-xs);
    }
    @media screen and (min-width: 1052px) {
      padding: var(--offset-s) var(--offset-s);
      margin-bottom: var(--offset-s);
    }
  }

  &-header, &-festival {
    justify-content: space-between;
    display: flex;
    padding-bottom: var(--offset-x);
    border-bottom: 1px dashed var(--color-shadow);
    margin-bottom: var(--offset-x);
    flex-direction: column;

    .h5 {
      margin-bottom: 0;
      font-size: 75%;

      & + div {
        display: flex;
        justify-content: space-between;

        @media screen and (min-width: 601px) {
          flex-direction: row;
          justify-content: initial;
        }
      }

      @media screen and (min-width: 601px) {
        font-size: 112.5%;
      }
    }

    .-hint- {
      &:first-child {
        padding-left: 0;
      }
    }

    @media screen and (min-width: 601px) {
      flex-direction: row;
      align-items: center;
    }

    @media screen and (min-width: 1052px) {
      padding-bottom: var(--offset-s);
      margin-bottom: var(--offset-s);
    }
  }

  &-event {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    img {
      margin-right: var(--offset-x);
      border-radius: var(--radius-l);
      //display: none;

      @media screen and (min-width: 601px) {
        max-height: 104px;
        margin-right: var(--offset-s);
        display: initial;
      }
      @media screen and (min-width: 801px) {
        max-height: 124px;
      }
    }

    &-body {
      flex-grow: 1;
      margin-right: var(--offset-x);
      display: flex;
      flex-direction: column;
      height: 100%;
      max-width: calc(100% - 90px);
      overflow: hidden;

      b {
        color: var(--color-title);
        margin-bottom: var(--offset-xx);
      }

      div {
        color: var(--color-text-light);
        font-size: 87.5%;
        margin-top: var(--offset-xx);
        width: calc(100% + 90px);
      }

      @media screen and (min-width: 601px) {
        b {
          margin-bottom: 0;

          & + div {
            margin-top: auto;
          }
        }
        div {
          font-size: 100%;
        }
      }

      @media screen and (min-width: 801px) {
        min-height: 124px;
      }

    }

    &.-media {
      justify-content: flex-end;
      margin-top: 5px;

      @media screen and (min-width: 801px) {
        display: none;
      }
    }

    .link--btn {
      //align-self: flex-end;
      position: relative;
      top: -2px;
      font-size: 75%;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }

      span {
        display: none;
      }

      @media screen and (min-width: 801px) {
        font-size: 100%;
        align-self: flex-end;
        top: 0;
      }

      &.-desktop {
        @media screen and (max-width: 800px) {
          display: none;
        }
      }
    }

  }

  &-season {
    margin: var(--offset-s) -56px 0;
    background-color: var(--color-bg);
    border-top: 1px solid var(--color-shadow);
    border-bottom: 1px solid var(--color-shadow);
  }

  &-footer {
    padding-top: var(--offset-sx);
    padding-bottom: var(--offset-xx);
    justify-content: space-between;
    display: none;
    line-height: 1.35;

    div {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      font-size: 12px;
      text-align: right;

      b {
        font-size: 16px;
        margin-bottom: 6px;
      }
    }

    @media screen and (min-width: 601px) {
      div {
        font-size: 100%;
      }
    }

    @media screen and (min-width: 1052px) {
      padding-top: var(--offset-s);
      padding-bottom: 0;
    }
  }

  .ticket--code {
    height: 66px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    svg {
      max-width: 100%;
    }
  }

  &-ticket {
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
    border-radius: var(--radius-l);
    background-color: var(--color-bg);
    padding: var(--offset-xs) var(--offset-x) var(--offset-x);
    color: var(--color-text-light);
    justify-content: space-around;
    width: 100%;

    .apple-pass {
      text-align: center;

      img {
        max-height: 42px;
      }
    }

    .card-event--detail-share  {
      padding: var(--offset-xx) 0  var(--offset-xx);
    }

    b {
      margin-bottom: var(--offset-x);
    }

    .btn--load-ticket {
      margin-top: auto;
    }

    .btn--wallet {
      margin: var(--offset-x) 0 var(--offset-x);
    }

    .btn--link-easy {
      align-self: center;
    }

    @media screen {
      transition: all .2s;

      &:hover {
        box-shadow: 0 1px 4px var(--color-shadow);
      }
    }

  }

  &-list {
    padding: var(--offset-x);
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    flex-direction: row;
    width: 100%;
    //overflow-x: auto;
    scroll-behavior: smooth;
    
    @media screen and (max-width: 960px) {
      &:after {
        content: '';
        display: flex;
        width: 1px;
        height: 100%;
        background-color: transparent;
        vertical-align: top;
        flex-shrink: 0;
      }
    }

    .profile--order-ticket {
      margin: 0 var(--offset-xx) 0 0;
      width: 240px;
      height: 350px;
      flex-shrink: 0;

		&.dspl-n {
			display: none;
		}

      @media screen and (min-width: 601px) {
        //margin: 0 var(--offset-x) 0 0;
      }
      @media screen and (min-width: 961px) and (max-width: 1280px) {
        //margin: 0 0 var(--offset-x) 0;
        width: calc((100% - var(--offset-x)) / 2);
        // &:nth-child(2n) {
          //margin-left: var(--offset-x);
        // }
      }
      @media screen and (min-width: 1281px) {
        width: calc((100% - var(--offset-s)) / 3);
        //margin: 0 0 var(--offset-x) 0;
        // &:nth-of-type(3n+2) {
          //margin: 0 var(--offset-x) var(--offset-x);
        // }
      }
    }

    &-area {
      //overflow: hidden;
      display: none;
      width: calc(100% + 2 * var(--offset-x));
      margin-left: calc(-1 * var(--offset-x));
      border-top: 1px dashed var(--color-shadow);
      margin-top: var(--offset-x);
      border-bottom: 1px dashed var(--color-shadow);

      @media screen and (min-width: 1052px) {
        margin-top: var(--offset-s);
      }

      @media screen and (min-width: 961px) {
        margin-left: 0;
        width: 100%;
        height: auto;
      }
    }

    @media screen and (min-width: 961px) {
      flex-wrap: wrap;
      padding: var(--offset-x) 0;
    }

  }

  &[data-view=hide] {
    .profile--order-event {
      .link--btn {
        span:first-child {
          display: initial;
        }
      }
    }
    @media screen and (max-width: 801px) {
      .profile--order-event {
        &-body div,
        img {
        //  display: none;
        }
      }
    }
  }

  &[data-view=show] {
    .profile--order {
      &-event {
        .link--btn {
          span:last-child {
            display: initial;
          }
        }
      }
      &-event-body {
        @media screen and (min-width: 601px) {
          min-height: 104px;
        }
        @media screen and (min-width: 801px) {
          min-height: 124px;
        }
      }
      &-season,
      &-list-area {
        display: block;
      }
      &-footer {
        display: flex;
      }
    }
  }

}

.dropdown__btns-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.dropdown__expanded {
  width: 100%;
}

.dropdown__btns-wrapper .dropdown--trigger-item {
  font-weight: 600;
  border: 1px solid #011031;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 16px;
  line-height: 24px;
  color: #011031;
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.profile--order-note-active,
.profile--order-note-process,
.profile--order-note-archive {
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  width: 190px;
  height: 30px;
  color: #160000;
}

.profile--order-note-active {
  background: rgba(77, 176, 97, 0.2);
}

.profile--order-note-archive {
  background: rgba(209, 214, 227, 0.2);
}

.profile--order-note-process {
  background: rgba(#ee4256, .2);
}

.profile--order-row {
  .profile--order-list-area .h5 {
    padding: var(--offset-s) var(--offset-sx) 0;
  }
  form {
    padding: var(--offset-s) var(--offset-sx);
  }
}

.invoice--form {

  .form--line {

    &[data-radio="individual"] {
      & ~ .form--group[data-field-companyName],
	    & ~ .form--group[data-field-inn],
	    & ~ .form--group[data-field-settlementAccount],
	    & ~ .form--group[data-field-mfo],
	    & ~ .form--group[data-field-egrpo],
	    & ~ .form--group[data-field-headPersonName],
	    & ~ .form--group[data-field-headPersonPosition],
	    & ~ .form--group[data-field-address],
	    & ~ .form--group[data-field-legalAddress] {
        display: none;
      }
    }
    &[data-radio="individual_entrepreneur"] {
      & ~ .form--group[data-field-companyName],
      & ~ .form--group[data-field-settlementAccount],
      & ~ .form--group[data-field-mfo],
      & ~ .form--group[data-field-egrpo],
      & ~ .form--group[data-field-headPersonName],
      & ~ .form--group[data-field-headPersonPosition],
      & ~ .form--group[data-field-address] {
        display: none;
      }
    }
    &[data-radio="legal_entrepreneur"] {
      & ~ .form--group[data-field-fullName] {
        display: none;
      }
    }

  }

}