.quick-search-form {
  width: 100%;
  padding-top: var(--offset-xx);
  display: flex;

  label {
    width: 16px;
    align-self: center;
    color: var(--color-primary);
    margin-right: -16px;
    flex-shrink: 0;

    svg {
      fill: currentColor;
    }
  }

  input {
    height: 36px;
    border: 0 solid var(--color-shadow);
    border-bottom-width: 1px;
    padding: 0 0 0 28px;
    width: 100%;
    font-size: 100%;
    background-color: transparent;
    transition: all .3s;
  }

  button {
    flex-shrink: 0;
    width: 40px;
    height: 36px;
    margin-left: -40px;
  }

  &[data-open='0'] {
    button {
      display: none;
    }
  }

  &[data-open='1'] {
    input {
      padding: 0 40px 0 28px;
    }
  }

  @media screen and (min-width: 801px) {
    width: auto;
    padding-top: 0;

    input {
      width: initial;
      min-width: 260px;
    }
  }

}
