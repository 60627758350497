.section--pages {
  padding: 0 0 var(--offset-x) 0;

  .section-archive--aside {
    overflow: hidden;
    height: 48px;
    margin-left: calc(-1 * 100% / 18 );
    width: calc(100% + 100% / 9);
    margin-bottom: var(--offset-s);
    //will-change: transform;
    //transition: transform .2s;

    @media screen and (min-width: 961px) {
      margin-left: 0;
      height: auto;
      margin-bottom: 0;
      overflow: initial;
    }
  }

  @media screen and (min-width: 601px) {
    padding: 0 0 var(--offset-xs) 0;
  }

  @media screen and (min-width: 961px) {
    padding: var(--offset-sm) 0 var(--offset-sm) 0;
  }
}

.pages--nav {
  display: flex;
  flex-direction: row;
  padding: 0 0 0 calc(5% - var(--offset-x));
  height: 72px;
  overflow-x: auto;
  scroll-behavior: smooth;
  overscroll-behavior-x: none;
  background-color: var(--color-title);
  color: #fff;


  @media screen and (min-width: 961px) {
    padding: 0;
    height: auto;
    overflow: initial;
    flex-direction: column;
    box-shadow: 0 1px 4px var(--color-shadow);
    background-color: #fff;
    color: var(--color-text-light);
    border: 1px solid var(--color-shadow);
    border-radius: var(--radius-l);
    //padding: var(--offset-x) var(--offset-sx);
  }

  a {
    display: flex;
    align-items: center;
    height: 48px;
    color: currentColor;
    white-space: nowrap;
    padding: 0 var(--offset-x);

    &.-current- {
      font-weight: 700;
      color: var(--color-primary);
    }

    @media screen {
      &:hover {
        color: var(--color-primary);
      }
    }

    &:last-child {
      padding-right: var(--offset-s);
    }

    @media screen and (min-width: 961px) {
      height: 60px;

      &:hover {
        color: var(--color-title);
      }

      &.-current- {
        color: var(--color-title);
      }

      &:last-child {
        padding-right: 0;
      }
      & + a {
        border-top: 1px solid var(--color-shadow);
      }
    }

  }

}

.page--row {
  margin-bottom: var(--offset-xs);

  .h1 {
    margin-bottom: var(--offset-xs);
  }

  &:last-child {
    margin-bottom: 0;
  }

  .-notice- {
    margin-bottom: var(--offset-s);
  }

  @media screen and (min-width: 961px) {
    border-radius: var(--radius-l);
    box-shadow: 0 1px 4px var(--color-shadow);
    background-color: #fff;
    border: 1px solid var(--color-shadow);
    padding: var(--offset-s) var(--offset-sm);

    .h1 {
      margin-bottom: var(--offset-s);
    }
  }

  @media screen and (min-width: 1281px) {
    margin-bottom: var(--offset-s);
    padding: var(--offset-sm) var(--offset-m);
  }
}
